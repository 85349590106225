/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

:root {
  --color-white: #ffffff;
  --color-gray: #dddddd;
  --color-blue: #1f8cf1;
  --color-light-blue: #c7e2fb;
  --color-text: #3e4152;
  --color-dark-text: #1e2742;
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


@media screen and (max-width: 575px) {
  .no-gotra-label{
    padding: 5px;
    margin: 5px;
    font-size: 13px;
    background: yellow;
    font-weight: 600;
  }
      
  .filter-container input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .filter-container label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
  }

  .filter-container label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  .filter-container input:checked + label {
    background: #1f8cf1;
  }

  .filter-container input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  .filter-container label:active:after {
    width: 18px;
  }

  .filter-body-right {
    overflow-y: auto;
    position: relative;
  }

  .filter-container .scroll-bar {
    position: absolute;
    right: 5px;
    top: 0;
    width: 8px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }

  .filter-container .scroll-bar.visible {
    opacity: 1;
  }

  .filter-container .scroll-bar-thumb {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    cursor: pointer;
  }

  /* Customize the appearance of the scrollbar thumb */
  /* For Webkit based browsers */

  .filter-body-right::-webkit-scrollbar {
    width: 8px;
  }

  .filter-body-right::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .filter-body-right::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  /* For Firefox */

  .filter-body-right {
    scrollbar-width: thin;
  }

  .filter-body-right scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  .filter-container {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--color-white);
    color: var(--color-dark-text);
    z-index: 999;
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .filter-container.slide-out {
    -webkit-animation-name: slideOut;
    animation-name: slideOut;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .filter-container .white-space {
    font-size: 0;
    height: 8px;
    background-color: var(--color-white);
  }

  .filter-header {
    height: 11.55%;
    padding: 45px 20px 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
  }

  .filter-header-filterhead {
    -ms-flex-preferred-size: 42%;
    flex-basis: 42%;
  }

  .filter-header-clearall,
  .filter-header-filterhead {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  .filter-header-clearall {
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    padding: 6px 10px 6px 25px;
    position: relative;
    cursor: pointer;
  }

  .filter-header-clearall img {
    position: absolute;
    top: 20%;
    left: 5%;
  }

  .filter-body {
    height: 82%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .filter-body-left {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    -ms-flex-preferred-size: 39%;
    flex-basis: 39%;
    background: #f6f6f6;
    overflow: scroll;
  }

  .filter-body-left ul {
    padding: 0;
    margin-bottom: 0;
    overflow-y: auto;
  }

  .filter-body-left ul li {
    height: 40px;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 150%;
    border-bottom: 1px solid var(--color-gray);
    cursor: pointer;
    text-transform: capitalize;
    text-wrap: balance;
  }

  .filter-body-left ul li span {
    margin-left: 8px;
  }

  .filter-body-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-preferred-size: 64%;
    flex-basis: 64%;
    text-align: center;
  }

  .filter-container .search-icon-bar {
    width: 100%;
    position: relative;
    border: 1px solid var(--color-gray);
  }

  .filter-container .search-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    padding-right: 5px;
    top: 10px;
    left: 11px;
  }

  .filter-body-right input {
    padding: 10px 0 10px 26px;
    color: var(--color-text);
    border-width: 0;
    background-color: transparent;
    font-size: 12px;
    width: 100%;
    outline: none;
  }

  .self-gotra-checkbox-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .self-gotra-checkbox-label input {
    width: initial;
  }

  .self-gotra-checkbox-label label {
    text-transform: capitalize;
  }

  .filter-body-right>p {
    padding-top: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .filter-body-right ul {
    width: 90%;
    -ms-flex-preferred-size: 69%;
    flex-basis: 69%;
    padding: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-y: auto;
  }

  .filter-body-right ul li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 16px;
    padding: 3px 9px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid var(--color-gray);
    border-radius: 1000vmax;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }

  .filter-body-right p:last-child {
    width: 76%;
    position: relative;
    bottom: 23px;
    display: block;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    text-wrap: balance;
  }

  .filter-body-right p:last-child::before {
    content: "";
    position: absolute;
    background-color: var(--color-gray);
    width: 112%;
    height: 1px;
    top: 14px;
    left: -12px;
  }

  .filter-apply {
    height: 6.45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* background: var(--color-blue); */
    color: var(--color-white);
    font-size: 14px;
    cursor: pointer;
  }

  .filter-apply button {
    border: none;
  }

  .filter-container .active {
    font-weight: 700;
    background-color: var(--color-white);
    border: none;
    border-top: 1px solid var(--color-gray) !important;
    border-bottom: 1px solid var(--color-gray) !important;
  }

  .filter-body-right .selected-filter-value {
    border: 1px solid transparent !important;
    background: var(--color-light-blue);
  }

  .filter-container .trash {
    padding-right: 5px;
  }

  .filter-container .cancel {
    -ms-flex-preferred-size: 28.33%;
    flex-basis: 28.33%;
    font-size: 14px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: var(--color-gray);
    text-align: end;
  }

  .filter-container .cancel button {
    background: none;
    border: none;
  }

  .filter-body-right ul p {
    display: none;
  }

  .filter-container footer {
    display: none;
  }

  .filter-body-left ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .enabled {
    background-color: #1f8cf1;
  }
.disabled {
  background-color: #3e4152;
}
  /* .filter-body-left ul li:first-child {
    order: 12;
  }
  .filter-body-left ul li:nth-child(2) {
    order: 11;
  }
  .filter-body-left ul li:nth-child(3) {
    order: 13;
  }
  .filter-body-left ul li:nth-child(4) {
    order: 10;
  }
  .filter-body-left ul li:nth-child(5) {
    order: 7;
  }
  .filter-body-left ul li:nth-child(6) {
    order: 5;
  }
  .filter-body-left ul li:nth-child(7) {
    order: 6;
  }
  .filter-body-left ul li:nth-child(8) {
    order: 9;
  }
  .filter-body-left ul li:nth-child(9) {
    order: 4;
  } */
}

@media only screen and (max-width: 376px) {
  .filter-header {
    padding: 44px 11px 17px;
    font-size: 12px !important;
  }

  .filter-header-filterhead {
    font-size: 14px;
  }

  .filter-container ul li {
    font-size: 10px !important;
  }

  .filter-body-right p {
    font-size: 12px;
  }

  .filter-header-clearall {

    padding: 5px 0 6px 19px;
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
    font-size: 14px;
  }

  .filter-header-filterhead {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }

  .filter-container .cancel {
    -ms-flex-preferred-size: 16.33%;
    flex-basis: 16.33%;
  }

  .filter-body-left {
    -ms-flex-preferred-size: 48%;
    flex-basis: 48%;
  }

  .filter-body-left ul li {
    text-wrap: balance;
  }

  .filter-body-right p:last-child {
    width: 83%;
    font-size: 12px;
  }

  .filter-body-right p:last-child::before {
    width: 92%;
    left: 6px;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slideOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}