@import url("https://fonts.googleapis.com/css?family=Sail");
@import url("https://fonts.googleapis.com/css?family=Open Sans");
.LandingPage-main-container {
  width: 100%;
  overflow: hidden;
  height: fit-content;
  background-color: #ffffff;
}
.LandingPage-main-container .bgImage {
  width: 100%;
}
.LandingPage-main-container .bgImage img {
  width: 100%;
}
.LandingPage-main-container .heading {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
  font-size: 24px;
  font-weight: 400;
  font-family: "Sail";
  text-align: center;
  color: #F11F45;
}
.LandingPage-main-container .heading2 {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #000000;
}
.LandingPage-main-container .btn-signIn {
  height: 50px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 8px;
  background-color: #F11F45;
  text-align: center;
}

.LandingPage-main-container .btn-signIn button {
  background: none;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 6px;
  margin-top: 6px;
}

.LandingPage-main-container .term-Policy {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
  /* padding: 0 38px; */
}
.LandingPage-main-container .term-Policy img {
  width: 40%;
  margin-top: 25px;
  height: 80px;
  
}
.LandingPage-main-container .term-Policy p {
  width: 100%;
  padding: 0 20px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #000000;
}

.LandingPage-main-container .ellipseDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background-color: #F6F6F6;
}
.LandingPage-main-container .ellipseContent {
  height: 90%;
  width: 95%;
  position: absolute;
  top: 0px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LandingPage-main-container .ellipseDiv img {
  width: 100%;
}
.LandingPage-main-container .headingDiv {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
}
.LandingPage-main-container .headingDiv img {
  width: 42px;
  height: 38px;
}
.LandingPage-main-container .headingDiv p {
  font-size: 16px;
  font-weight: 700;
  line-height: 24;
  margin-top: 10px;
}
.LandingPage-main-container .contentDiv {
  width: 95%;
  height: 230px;
}
.LandingPage-main-container .contentDiv p {
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
}
.LandingPage-main-container .lineGrp {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.LandingPage-main-container .line {
  width: 20%;
  height: 1px;
  background-color: #cd373e;
}
.LandingPage-main-container .line.active {
  height: 2px;
}
.LandingPage-main-container .feature {
  margin-left: 20px;
  margin-top: 40px;
  width: 100%;
  color: #f11f45;
  font-size: 16px;
  font-weight: 700;
}
.LandingPage-main-container .feature-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-spacing: -2px;
  text-align: justify;
}
.LandingPage-main-container .content {
  margin: 10px 20px;
  gap: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.LandingPage-main-container .iconImg {
  width: 10%;
  padding-top: 10px;
}
.LandingPage-main-container .detail-content {
  width: 80%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10pxpx;
  border-bottom: 1px solid #dddddd;
}
.LandingPage-main-container .iconImg img {
  width: 24px;
  height: 24px;
}
.LandingPage-main-container .feature-heading {
  height: 20%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.LandingPage-main-container .feature-describe {
  height: 80%;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
}
.LandingPage-main-container .footerMobile {
  width: auto;
  background-color: #f6f6f6;
  padding-bottom: 20px;
}
.LandingPage-main-container .footerNavlink {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-top: 19px;
}
.LandingPage-main-container .company {
  width: 40%;
  font-weight: 600;
  line-height: 22px;
  color: #1f8cf1;
}
.LandingPage-main-container .company ul li {
  margin-bottom: 10px;
}
.LandingPage-main-container .privacy {
  width: 60%;
  font-weight: 600;
  line-height: 22px;
  color: #1f8cf1;
}
.LandingPage-main-container .privacy ul li {
  margin-bottom: 10px;
}
.LandingPage-main-container .Head {
  font-weight: 400;
  color: #000000;
}
.LandingPage-main-container .footerImg {
  margin: 20px 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #dddddd;
}
.LandingPage-main-container .footerTerms {
  font-size: 14px;
  font-weight: 600;
  color: #a4a4a4;
  margin: 20px 20px;

  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
.LandingPage-main-container .footerCopyright {
  margin: 20px 20px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 18px;
  padding: 0 6px;
}
/* menubar */
.LandingPage-main-container .mobileMenu {
  position: absolute;
  height: 765px;
  width: 300px;
  background-color: #ffffff;
  right: 0;
  top: 10;
}
.LandingPage-main-container .sideMenubar {
  margin-top: 20px;
  position: fixed;
  background: rgb(0, 0, 0, 0.2);
  width: 100%;
  height: 859px;
  z-index: 3;
  top: 0;
}
.LandingPage-main-container .closeIcon {
  margin-top: 20px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
}
.LandingPage-main-container .MenuList {
  width: 100%;
  margin-top: 30px;
  font-size: 16px;
}
.LandingPage-main-container .list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 40px;
  align-items: center;
  text-align: center;
  margin: 10px 20px 0 0;
}
.LandingPage-main-container .list:hover {
  background-color: #f6f6f6;
}
.LandingPage-main-container .list span {
  width: 60%;
  text-align: justify;
  text-align: start;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 30px;
}
.LandingPage-main-container .sideMenuFooter {
  display: flex;
  flex-direction: row;
  margin-top: 150px;
  margin-left: 20px;
  justify-content: space-around;
  font-size: 14px;
  font-weight: 600;
  color: #a4a4a4;
  border-top: 1px solid #dddddd;
}
.LandingPage-main-container .carousel-indicators [data-bs-target] {
  background-color: red !important;
}
@media screen and (max-width: 280px) {
  .LandingPage-main-container .logo {
    font-size: 16.33px;
  }
  .LandingPage-main-container .heading p {
    font-size: 14px;
  }
  .LandingPage-main-container .term-Policy {
    padding: 0;
  }
  .ellipseDiv {
    margin-top: 0;
  }
  .LandingPage-main-container .ellipseContent {
    top: 12px;
  }
  .LandingPage-main-container .feature {
    margin-top: 0;
  }
  .LandingPage-main-container .headingDiv {
    width: fit-content;
  }
  .LandingPage-main-container .headingDiv img {
    width: 24px;
    height: 26px;
  }
  .LandingPage-main-container .headingDiv p {
    font-size: 14px;
  }
  .LandingPage-main-container .contentDiv {
    height: 200px;
  }
  .LandingPage-main-container .contentDiv p {
    font-size: 12px;
  }
  .LandingPage-main-container .mobileMenu {
    width: auto;
  }
  .LandingPage-main-container .content p {
    font-size: 14px;
  }
  .LandingPage-main-container .footerNavlink {
    font-size: 14px;
    padding-left: 0;
  }
  .LandingPage-main-container .footerTerms {
    gap: 10px;
  }
  .LandingPage-main-container .sideMenuFooter {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .LandingPage-main-container .MenuList {
    font-size: 14px;
  }
  .LandingPage-main-container .closeIcon {
    width: 12px;
    height: 12px;
  }
}
