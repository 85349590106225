.mypreference {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
}
.mypreference .mypreference-fields {
  display: flex;
  flex-direction: row;
}
.mypreference .mypreference-fields .mypreference-inputs {
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}
.mypreference-inputs label {
  flex-basis: 30%;
}
.mypreference-inputs div {
  flex-basis: 50%;
}
.mypreference-inputs div select {
  width: 100%;
  padding: 2%;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #000;
  flex-basis: 48%;
  border-radius: 5px;
}
.mypreference-radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.mypreference-input input {
  padding: 2%;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #000;
  flex-basis: 48%;
  border-radius: 5px;
  width: 100%;
}
.saveclosebtns {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.saveclosebtns button {
  padding: 6px 10px;
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.5s ease-in;
  border-radius: 5px;
}
@media only screen and (max-width: 870px) {
  .mypreference {
    padding: 10px;
  }
  .mypreference .mypreference-fields .mypreference-inputs {
    padding: 8px 10px;
  }
  .saveclosebtns {
    padding: 5px 10px;
  }
  .mypreference-inputs div {
    flex-basis: 65%;
  }
}
@media only screen and (max-width: 700px) {
  .mypreference .mypreference-fields {
    flex-direction: column;
  }
  .mypreference-radio {
    gap: 20px;
  }
}
