.policy_content .wrapper p:nth-of-type(1) {
  font-weight: 700;
}
.policy_content {
  padding: 2% 0;
}
.policy_content .wrapper {
  display: flex;
  flex-direction: column;
}
.policy_content .wrapper p {
  padding: 0 0.5%;
  text-align: justify;
  font-size: 18px;
}