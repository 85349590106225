/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.myprofile-wrapper {
  max-width: 1360px;
  width: 87.13%;
  margin: 0 auto;
}

.myprofile-heading {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 15px;
  margin-bottom: 20px;
}

.myprofile-heading h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.myprofile-heading h2 span:nth-of-type(1),
.myprofile-heading h2 span:nth-of-type(3) {
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
}

.myprofile-heading h2 span:nth-of-type(2) {
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
  text-align: center;
}

.navtabs {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navtabs li {
  border: 1px solid #d1d1d1;
  padding: 12px 6px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 16px;
}

.navtabs li:first-child {
  border-top-left-radius: 5px;
}

.navtabs li:last-child {
  border-top-right-radius: 5px;
}

.bg-border {
  background-color: #d1d1d1;
}

.bg-green {
  background-color: #7cd67c;
}

.bg-orange {
  background-color: #fcc153;
}

.current {
  border: 25px solid;
}

.tab-border {
  border: 3px solid #888787 !important;
}

/* .profile-image form {
  position: relative;
} */

@media only screen and (max-width: 1024px) {
  .navtabs li {
    font-size: 14px;
    padding: 8px 4px;
    letter-spacing: 0px;
  }
}

/* @media only screen and (max-width: 384px) {
  .navtabs li {
    font-size: 12px;
    padding: 4px 2px;
  }
  .tab-border {
    border: 2px solid #888787 !important;
  }
} */

@media only screen and (max-width: 768px) {

  .back-editMyProfile {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 20px;
    text-transform: capitalize;
  }

  .back-editMyProfile>span:nth-of-type(1) {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }

  .back-editMyProfile>span:nth-of-type(2) {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }

  .navtabs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .profile_featured {
    position: relative;
  }

  .profile_heading,
  .profile_featured {
    padding: 2%;
    border-bottom: 1px solid #d1d1d1;
  }

  .profile_heading h3 {
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .profile-image {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .profile-image img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .profile_featured>h3 {
    margin-bottom: 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: capitalize;
  }

  .label_container {
    width: 150px;
    height: 150px;
    margin-bottom: 2%;
    outline: 2px solid #3b9af2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline-style: dashed;
    border-radius: 10px;
  }

  .label_container>label {
    font-size: 100px;
  }

  .label_container>label>input {
    display: none;
  }

  .uploaded_profile .view-loader-container {
    margin: 0 auto;
  }

  .uploaded_profile {
    /* height: 57vh; */
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .uploaded_profile_featured {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 3.3%;
  }

  .uploaded_photo {
    width: 200px;
    height: 240px;
    padding: 10px 0;
    margin: 0 4.6% 25px 0;
    -ms-flex-preferred-size: 21.5%;
    flex-basis: 21.5%;
  }

  .uploaded_profile>.uploaded_photo:nth-child(4n) {
    margin-right: 0;
  }


  .uploaded_profile .uploaded_photo>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .no_photo {
    text-align: center;
    text-transform: uppercase;
  }

  .profile_featured>.add_profile-photo {
    width: 100%;
    height: 91vh;
    padding: 3%;
    z-index: 1000;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  .profile_featured>.add_profile-photo h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-transform: capitalize;
  }

  .profile_featured>.add_profile-photo h3 span:first-child {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }

  .add_profile-photo h4 {
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: capitalize;
  }

  .add_profile-photo h4>.activeToggle {
    color: red;
  }

  .uploaded_photo_featured {
    -ms-flex-preferred-size: 31.1%;
    flex-basis: 31.1%;
    height: 250px;
    margin: 10px 0;
    position: relative;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .6);
    box-shadow: 0 0 5px rgba(0, 0, 0, .6);
    border-radius: 10px;
  }

  .image_conatiner {
    height: 100%;
  }

  .image_conatiner>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .delete-btn {
    padding: 3%;
    position: absolute;
    top: 2%;
    right: 5%;
    background-color: #ddd;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
  }

  .upload_popup_open {
    width: 100%;
    height: 85vh;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .popup_btn {
    padding: 2%;
    margin: 2%;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

  .popup_btn h5 {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .upload_popup_close {
    display: none;
  }

  .load_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .user_edits_icon {
    padding: 1% 2%;
    border: 1px solid #ddd;
    position: absolute;
    bottom: 0;
    right: 36%;
    background-color: #fff;
    border-radius: 50%;
    font-size: 20px;
  }

  .user_images_edits {
    width: 100%;
    height: 100vh;
    z-index: 10000000;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(138, 142, 156, .3);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  .user_images_edits .label_div {
    width: 100%;
    height: 34vh;
    z-index: 100000000;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .user_images_edits .profile_cropper {
    width: 100%;
    height: 400px;
    position: absolute;
    top: 5%;
    left: 0;
    background-color: #fff;
  }

  .user_images_edits .label_div h6 {
    padding: 2%;
    margin: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #d1d1d1;
  }

  .user_images_edits .label_div h6>span:nth-of-type(1) {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }

  .user_images_edits .label_div h6>span:nth-of-type(2) {
    font-size: 20px;
    font-weight: 600;
  }

  .user_images_edits .label_div ul li > span:first-child {
    font-size: 14px;
    font-weight: bold;
  }

  .label_div ul li:nth-of-type(1) input {
    display: none;
  }

  .label_div ul li:not(:last-child) {
    margin-bottom: 12px;
  }

  .label_div ul li:last-child {
    color: #f00;
  }

  .label_div ul li>label,
  .label_div ul li:not(:first-child) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 20px;
    font-weight: 700;
  }

  .label_div ul li>label span:nth-of-type(1),
  .label_div ul li:not(:first-child) span:nth-of-type(1) {
    -ms-flex-preferred-size: 5%;
    flex-basis: 5%;
  }

  .saveProfile {
    width: 70px;
    padding: 5px;
    outline: none;
    border: none;
    border: 1px solid transparent;
    background-color: #0d99ff;
    background-clip: border-box;
    color: rgba(255, 255, 255, 1);
    font-weight: 900500;
    text-align: center;
    letter-spacing: 1.2px;
  }

  .hide_content {
    display: none;
  }

  .show_content {
    display: block;
  }

  .footer_section {
    padding: 2%;
  }

  .footer-section {
    width: 90%;
    height: 10vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #dddddd;
  }

  .footer-section>button {
    all: unset;
  }
}

@media only screen and (max-width: 580px) {
  .uploaded_photo {
    height: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .profile-image img {
    width: 150px;
    height: 150px;
  }

  .uploaded_photo {
    height: 130px;
  }

  .uploaded_photo_featured {
    height: 180px;
  }

  .label_container {
    width: 80px;
    height: 80px;
  }

  .add_profile-photo h4 {
    font-size: 18px;
  }

  .label_div ul li>label,
  .label_div ul li:not(:first-child) {
    font-size: 14px;
  }

  .saveProfile {
    width: 60px;
    padding: 3px;
    font-size: 12px;
    border-radius: 5px;
  }

  .user_edits_icon {
    right: 31%;
  }
}

@media only screen and (max-width: 320px) {
  .profile-image img {
    width: 120px;
    height: 120px;
  }

  .uploaded_photo {
    height: 110px;
  }

  .user_images_edits .label_div h6>span:nth-of-type(1) {
    -ms-flex-preferred-size: 15%;
    flex-basis: 15%;
  }

  .user_images_edits .label_div h6>span:nth-of-type(2) {
    font-size: 16px;
  }

  .label_div ul li:not(:last-child) {
    margin-bottom: 12px;
  }

  .label_div ul li>label span:nth-of-type(1),
  .label_div ul li:not(:first-child) span:nth-of-type(1) {
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
  }

  .label_div ul li>label span,
  .label_div ul li:not(:first-child) span {
    font-size: 12px;
  }

  .add_profile-photo h4 {
    font-size: 15px;
  }

  .uploaded_photo_featured {
    height: 130px;
  }
}