.loginPageMobile-main{
background: #1E274280;
}

.loginPageMobile {
  width: 100%;
  height: 94%;
  border-radius: 12px 12px 0px 0px;
  background: #f6f6f6;
  position: relative;
  top: 36px;
}

.login-field {
  position: relative;
  top: 72px;
}

.loginPage-main .loginPageMobile .swiper-container img {
  width: 100%;
  border-radius: 12px;
}

.login-signup {
  display: inline-block;
}

.loginPage-main .login-signup button{
  background: none;
  border: none;
  color: #1f8cf1;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
}

.login-signup::before,
.login-signup::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 90px;
  height: 2px;
  background-color: #dddddd;
}

.login-signup::before {
  right: 125px;
}

.login-signup::after {
  left: 125px;
}

.loginPage-main .btn {
  background-color: #1f8cf1;
  height: 50px;
  border-radius: 1px 0px 0px 0px;
  position: relative;
  top: 31px;
  right: 3px;
}

.loginPage-main .LoginContact {
  width: 320px;
  height: 80px;
  position: relative;
  left: 32px;
  border-radius: 8px;
}

.loginPage-main .login-field .LoginContact .input-text {
  color: black;
  position: relative;
  right: 10px;
  font-size: 14px;
  font-family: Open Sans;
}

.loginPage-main .login-field .LoginContact input {
  background-color: #ffffff;
  width: 231px;
  height: 28px;
  position: relative;
  padding: 9.5px 12px 9.5px 12px;
  border-radius: 8px;
  gap: 10px;
  border-color: darkgrey;
  left: 20px;
}

.loginPage-main .login-field .LoginContact .login-info-top .country-code {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: thin;
  border-radius: 0px;
  border-bottom-color: black;
  position: relative;
  top: 13px;
  outline: none;
  width: 200px;
  color: black;
}

.loginPage-main .login-field .LoginContact .login-info-top {
  width: 104%;
  position: relative;
  right: 12px;
}

.loginPage-main .login-field .LoginContact .login-info-top .country-code:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: thin;
  border-radius: 0px;
  border-bottom-color: black;
  position: relative;
  top: 13px;
}

.loginPage-main .btn img {
  position: relative;
  bottom: 2px;
}

.simple-line {
  border: 0;
  background-color: grey;
  position: relative;
  width: 90%;
  top: 22px;
  left: 17px;
}

.TandC {
  position: absolute;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
}

.closeImg {
  width: 40%;
}

.loginPage-main .swiper-pagination {
  width: 100%;
  height: 10px;
  z-index: 9;
  top: 95% !important;
}

.loginPage-main .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.loginPage-main .swiper-pagination-bullet-active {
  background: #f11f45 !important;
}

.loginPage-main .close-button {
  margin-top: 2px;
  position: absolute;
  border: none;
  outline: none;
  background: transparent;
  left: 25px;
  top: 15px;
}

.loginPage-main .close-button .close-button-img {
  width: 14px !important;
}

@media only screen and (width: 360px) and (height: 740px) {
  .loginPageMobile {
    width: 100%;
    height: 102% !important;
  }
}

.headerFlexColumn2 {
  display: none;
}

@media only screen and (max-width: 768px) {
  .loginPageMobile {
    width: 100%;
    height: 100%;
  }

  .main-input {
    margin-top: 45px;
  }

  .login-field {
    position: relative;
    top: 51px;
  }

  .login-signup {
    position: relative;
    left: 137px;
    top: 25px;
  }

  .loginPage-main .LoginContact {
    width: 82%;
  }

  .TandC {
    top: 80px;
    width: 67%;
    margin: auto;
    position: relative;
  }
}

@media (width: 390px) and (height: 844px) {
  .loginPageMobile {
    width: 100%;
    height: 94%;
  }
}
.loginPageMobile .LoginMobile-otpText {
  font-size: 14px;
  color: #1f8cf1;
  line-height: 70px;
  margin-top: 40px;
}

.loginPageMobile .LoginMobile-otp {
  margin: 0 auto;
  text-align: center;
}

.loginPageMobile .line {
  border: 0;
  background-color: grey;
  position: relative;
  width: 90%;
  height: 2px;
  top: 27px;
  align-self: center;
}

.LoginMobile-otp .OTP-phone-number {
  font-weight: 700;
}

.loginPageMobile .LoginMobile-otpText img {
  width: 24px;
  height: 20px;
  margin-left: 16px;
}

.loginPageMobile .resendText{
  font-size: 16px;
  margin-top: 28px;
}

.loginPageMobile .TC-otp{
  top: 26px;
}


