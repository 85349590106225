.education-content .wrapper {
  width: 100% !important;
}

.education-content form {
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .3);
}

.education-content form input,
.education-content form select {
  border: 0;
  border-bottom: 2px solid #000;
  border-radius: 5px;
}

.education-content form input:focus,
.education-content form select:focus {
  border-bottom: 2px solid #000;
  box-shadow: 0 0 0;
}

.education-content .education-form-group>div {
  padding: 2% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.education-content .education-form-group>div input,
.education-content .education-form-group>div select {
  flex-basis: 50%;
}

.education-content .saveclosebtns {
  display: flex;
  justify-content: space-between;
}

.education-content .saveclosebtns button {
  padding: 6px 10px;
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: .5s ease-in;
  border-radius: 5px;
}

@media only screen and (max-width : 1024px) {
  .education-content .saveclosebtns button {
    font-size: 14px;
  }
}

@media only screen and (max-width : 768px) {
  .education-content .education-form-group>div {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width : 1024px) {
  .education-content .saveclosebtns button {
    font-size: 12px;
  }
}