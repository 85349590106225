.footerMobile {
    width: auto;
    background-color: #f6f6f6;
    padding-bottom: 20px;
  }
  
  .footerNavlink {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-top: 19px;
  }
  
  .company {
    width: 40%;
    font-weight: 600;
    line-height: 22px;
    color: #1f8cf1;
  }
  
  .company ul li {
    margin-bottom: 10px;
  }
  
  .privacy {
    width: 60%;
    font-weight: 600;
    line-height: 22px;
    color: #1f8cf1;
  }
  
  .privacy ul li {
    margin-bottom: 10px;
  }
  
  .Head {
    font-weight: 400;
    color: #000000;
  }
  
  .footerTerms {
    font-size: 14px;
    font-weight: 600;
    color: #a4a4a4;
    margin: 20px 20px;
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  
  .footerCopyright {
    margin: 20px 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    padding: 0 6px;
  }
  