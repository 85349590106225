.aboutus-main-container {
  max-width: 100%;
  padding: 20px;
}

@media (max-width: 768px) {
  .aboutus-main-container {
    padding: 16px;
    background-color: #ffffff;
    font-family: Open Sans;
  }

  .aboutus-main-container h1 {
    font-size: 19px;
    font-weight: 700;
    line-height: 29px;
  }
  .aboutus-main-container h2 {
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }

  .aboutus-main-container p{
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .aboutus-main-container .about-us-points li {
    padding-left: 20px;
    list-style-type: disc;
    list-style-position:outside;
}
}
