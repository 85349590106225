.RichEditor-root {
  background: #fff;
  border: 1px solid #7d7272;
  font-size: 16px;
  padding: 15px;
  border-radius: 5px;
}

.RichEditor-editor {
  border-top: 1px solid #7d7272;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #fff;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #000;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
.about-save {
  display: inline-block;
  border: 1px solid black;
  padding: 0.5% 1% 0.5% 1%;
  border-radius: 10%;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #03b0cb;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px #999;
}

.about-save:hover {
  background-color: #03b0cb;
}

.about-save:active {
  background-color: #03b0cb;
  box-shadow: 0 2px #000;
  transform: translateY(4px);
}