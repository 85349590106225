.otp-form .login-right-side > form > input {
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid #000;
  text-align: center;
}
.login-right-side .resend-link {
  color: #6388d8;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;
}
.login-right-side .resend-link:disabled {
  color: grey;
  font-weight: 500;
}
.login-right-side .change-number {
  color: #3366cc;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  line-height: 16px;
}
.resend-otp-text {
  font-size: 12px;
  margin-top: 2px;
}

.form-box {
  position: relative;
}
.form-box input[type="number"] {
  width: 100%;
  padding: 3px 10px;
  border: 0;
  outline: 0;
  border: 1px solid #666666a3;
  border-radius: 5px;
}
.form-box > p {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.register_btn {
  margin-top: 10px;
}

.register_btn button {
  width: 100%;
  padding: 5px 0;
  border: 0;
  outline: 0;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
}
.close-btn {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 18px;
  cursor: pointer;
}
.otp-form > span {
  color: #0935f8;
  cursor: pointer;
  text-transform: capitalize;
}
.otp-form label {
  margin: 10px 0;
  font-weight: 400;
}

.sign-up form > div {
  display: flex;
}
.sign-up h3 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}

.sign-up form > div:not(:last-child) {
  margin-bottom: 10px;
}

.gender-checkbox {
  display: flex;
  flex-direction: row;
}

.gender-checkbox > div {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.gender-checkbox > div > div {
  display: flex;
}


.gender-checkbox > div > div input[type="checkbox"] {
  margin-right: 3px;
}

.sign-up form > div label {
  flex-basis: 50%;
  font-weight: 600;
}

.sign-up form > div input[type="text"],
.sign-up form > div input[type="email"],
.sign-up form > div input[type="date"] {
  padding: 3px 10px;
  border: 0;
  outline: 0;
  border: 1px solid #e6e7e7;
  flex: 4;
  border-radius: 5px;
  align-self: flex-end;
  margin-left: 3px;
  margin: 0;
}

.signup-btn {
  margin: 10px 0 30px;
  flex-direction: column;
}

.signup-btn > button {
  padding: 3px 8px;
  border: 0;
  outline: 0;
  background-color: #87ceeb;
  font-weight: 600;
  border-radius: 5px;
}

.signup-btn > button:nth-of-type(1) {
  margin-bottom: 10px;
}

.landing-banner {
  height: 80vh;
  position: relative;
}

.landing-banner img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.landing-banner p {
  width: 200px;
  padding: 15px 20px;
  margin: 25px 12px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  border-radius: 20px;
  line-break: auto;
}

.landing-banner p::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 48%;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  background-color: #fff;
  border-radius: 5px;
}

.landing-services {
  margin: 15px 0;
}

.landing-services h3 {
  margin: 20px 0;
  color: #ff1493;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.landing-services ul {
  padding: 0;
  margin: 0 6%;
  display: flex;
}

.landing-services li {
  flex: 1;
}

.landing-services li:not(:last-child) {
  margin-right: 10%;
}

.landing-services li h4 {
  margin-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}

.landing-services li h4::after {
  content: "";
  width: 28%;
  height: 3px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #ff1493;
}

.landing-services li p {
  font-family: math;
}

.landing-services li img {
  width: 100%;
  height: 100%;
}

.landing-biodata {
  padding: 0 6%;
  background: url("../../assets/biodata_background_image.jfif") no-repeat
    center/cover;
  display: flex;
}

.landing-biodata > div:nth-of-type(1) {
  flex: 1;
}

.landing-biodata > div:nth-of-type(1) h4 {
  width: 330px;
  margin: 60px 0 60px;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.2);
}

.landing-biodata > div:nth-of-type(1) a {
  padding: 12px 22px;
  border-radius: 20px;
  background-color: rgb(246, 85, 117);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 600;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}

.landing-biodata > div:nth-of-type(2) {
  height: 350px;
  flex: 1;
}

.landing-biodata > div:nth-of-type(2) img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.landing-about {
  padding: 0 6%;
  margin: 40px 0%;
  display: flex;
  background-color: #c1bfe2;
}

.landing-about > div:nth-of-type(1) {
  padding: 35px 10% 35px 0;
  flex: 3;
  text-align: center;
}

.landing-about > div:nth-of-type(1) h3 {
  padding: 0 15%;
  color: #000080;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.landing-about > div:nth-of-type(1) p {
  margin: 30px 0 40px;
  font-family: cursive;
  text-align: justify;
  line-height: 1.8;
}

.landing-about > div:nth-of-type(1) button {
  padding: 5px 20px;
  border: 0;
  border: 2px solid #fff;
  outline: 0;
  background-color: #0e0277;
  color: #fff;
  text-transform: capitalize;
  transition: 0.5s ease-in;
  letter-spacing: 1.2px;
}

.landing-about > div:nth-of-type(2) {
  width: 360px;
  height: 450px;
  flex: 1;
}

.landing-about > div:nth-of-type(2) img {
  width: 360px;
  height: 450px;
}

.landing-download {
  padding: 0 6%;
  display: flex;
  background-color: #000;
  color: #fff;
}

.landing-download > div:nth-of-type(1) {
  padding: 20px 0;
  margin-right: 10%;
  flex: 3;
}

.landing-download > div:nth-of-type(1) h3 {
  width: 320px;
  margin: 30px 0 0 5%;
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
}

.landing-download > div:nth-of-type(1) p {
  margin: 25px 0;
  font-size: 18px;
  text-transform: capitalize;
  line-break: auto;
}

.landing-download > div > div button {
  padding: 5px 20px;
  border: 0;
  border: 2px solid #fff;
  outline: 0;
  background-color: transparent;
  color: #fff;
  transition: 0.5s ease-in;
}

.landing-download > div > div button:nth-of-type(1) {
  margin-right: 20px;
}

.landing-download > div > div,
.landing-download > div > div button,
.landing-download > div > div button > span {
  display: flex;
}

.landing-download > div > div button > span {
  margin-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
}

.landing-download > div > div button > span > :nth-of-type(2) {
  font-weight: 600;
}

.landing-download > div:nth-of-type(2) {
  flex: 1;
}

.landing-download > div:nth-of-type(2) img {
  width: 275px;
  height: 500px;
}
.personal-details-card {
  white-space: nowrap;
  overflow-x: auto;
  height: 350px;
}
.personal-details-card::-webkit-scrollbar {
  display: none; /* For Chrome, safari and opera */
}
.personal-details-card {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.personal-details-card > h3 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.personal-details-card form > div {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  font-size: 16px;
}

.personal-details-card form > div > label {
  flex-basis: 50%;
  font-weight: 600;
}

.personal-details-card form > div > select {
  flex-basis: 50%;
  width: 50%;
  padding: 3px 10px;
  border: 0;
  outline: 0;
  border: 1px solid #e6e7e7;
  flex: 4;
  border-radius: 5px;
  align-self: flex-end;
  margin-left: 3px;
  margin: 0;
}

.personal-details-card form > div > input {
  width: 50%;
  padding: 3px 10px;
  border: 0;
  outline: 0;
  border: 1px solid #e6e7e7;
  flex: 4;
  border-radius: 5px;
  align-self: flex-end;
  margin-left: 3px;
  margin: 0;
}

.personal-details-error {
  color: red;
}

/* New card css begins here */
.login-info-top{
  display: flex;
}

.login-info-top > .country-code {
  flex-basis: 20%;
  height: 36px;
  border-color: #e6e7e7;
  border-radius: 5px;
}
.login-info-top > input {
  all: unset;
  border: 1px solid;
  flex-basis: 75%;
  height: 36px;
  padding: 0 8px;
  margin-left: 8px;
  border-color: #e6e7e7;
  border-radius: 5px;
  font-weight: 600;
}

.login-info-bottom > .login-get-otp {
  background-color: #000;
  color: #fff;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  border-radius: 5px;
  font-weight: 600;
}
.login-info-bottom > p {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
}

.login-close-btn {
  height: 30px;
  align-self: flex-end;
}
.login-right-side {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .landing-download > div > div button:hover {
    background-color: #fff;
    color: #000;
  }

  .landing-about > div:nth-of-type(1) button:hover {
    background-color: #fff;
    color: #0e0277;
  }
}

@media only screen and (max-width: 996px) {
  .login-info-top > input {
    width: 70%;
  }

  .login-info-top > .country-code {
    width: 20%;
  }

  .landing-banner p::before {
    left: 76%;
  }

  .landing-services li:not(:last-child) {
    margin-right: 6%;
  }

  .landing-services li img {
    width: 80%;
    height: 80%;
  }

  .landing-services li h4 {
    font-size: 20px;
  }

  .landing-about > div:nth-of-type(2),
  .landing-about > div:nth-of-type(2) img {
    width: 300px;
  }

  .landing-about {
    align-items: center;
  }

  .landing-about > div:nth-of-type(1) {
    padding: 35px 5% 35px 0;
    flex: 1;
  }

  .landing-about > div:nth-of-type(1) h3 {
    padding: 0;
    font-size: 25px;
  }

  .landing-download > div:nth-of-type(1) {
    flex: 1;
  }

  .landing-download > div:nth-of-type(1) h3 {
    margin: 0;
    font-size: 35px;
  }

  .landing-download > div > div button {
    padding: 5px 10px;
    align-items: center;
  }

  .landing-download > div > div button > span {
    margin-left: 5px;
    font-size: 10px;
  }

  .landing-services li p,
  .landing-download > div:nth-of-type(1) p,
  .landing-about > div:nth-of-type(1) p {
    font-size: 14px;
  }

  .signup-btn,
  .register_btn {
    margin: 15px 0 40px;
  }

  .sign-up form > div {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    font-size: 16px;
  }
  .sign-up form > div input[type="text"],
  .sign-up form > div input[type="email"],
  .sign-up form > div input[type="date"] {
    width: 100%;
  }
}
@media only screen and (max-width: 824px) {
  .gender-checkbox > div {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .login-info-top > input {
    width: 70%;
  }

  .login-info-top > .country-code {
    width: 20%;
  }
  .landing-banner p {
    width: auto;
    padding: 8px;
    margin: 8px 5px 0 0;
    font-size: 10px;
    font-weight: 500;
  }

  .landing-banner p::before {
    width: 15px;
    height: 15px;
    top: -6px;
    left: 86%;
  }

  .landing-banner {
    height: 100%;
  }

  .landing-services h3 {
    font-size: 22px;
  }

  .landing-services li {
    text-align: center;
  }

  .landing-about {
    margin: 20px 0;
  }

  .landing-services li img {
    width: 40%;
  }

  .landing-services li h4::after {
    left: 36%;
  }

  .landing-biodata > div:nth-of-type(1) h4 {
    width: 100%;
    margin: 40px 0;
    font-size: 30px;
  }

  .landing-biodata > div:nth-of-type(1) a {
    padding: 6px 10px;
  }

  .landing-about > div:nth-of-type(2) {
    margin-bottom: 10px;
  }

  .landing-download,
  .landing-download > div > div {
    align-items: center;
  }

  .landing-download > div:nth-of-type(1) h3 {
    width: 100%;
    font-size: 30px;
  }

  .landing-download > div > div {
    justify-content: space-evenly;
  }

  .landing-services li:not(:last-child),
  .landing-download > div:nth-of-type(1),
  .landing-download > div > div button:nth-of-type(1) {
    margin-right: 0;
  }

  .landing-services ul,
  .landing-biodata,
  .landing-about,
  .landing-download {
    flex-direction: column;
  }

  .personal-details-card form > div {
    flex-direction: column;
  }

  .personal-details-card form > div > select,
  .personal-details-card form > div > input {
    width: 100%;
  }
}
@media only screen and (max-width: 626px) {
  .login-info-top > input {
    width: 69%;
    margin: 0px 3px;
  }

  .login-info-top > .country-code {
    width: 20%;
  }
}

@media only screen and (max-width: 540px) {
  .login-info-top > input {
    width: 66%;
    margin: 0px 3px;
    height: 30px;
    font-size: 14px;
  }
  .sign-up form > div:not(:last-child) {
    margin-bottom: 5px;
  }
  .sign-up form > div label {
    font-size: 14px;
  }
  .sign-up form > div input[type="text"],
  .sign-up form > div input[type="email"],
  .sign-up form > div input[type="date"] {
    padding: 1px 2px;
    font-size: 14px;
  }
  .login-info-top > .country-code {
    width: 20%;
    height: 30px;
    font-size: 14px;
    backdrop-filter: blur(3px);
    background-color: transparent;
  }

  .login-info-bottom > .login-get-otp {
    height: 30px;
  }
}


@media only screen and (max-width: 360px) {
  .landing-banner p {
    font-size: 8px;
  }

  .landing-services h3,
  .landing-download > div:nth-of-type(1) h3 {
    font-size: 20px;
  }

  .landing-about > div:nth-of-type(1) {
    padding: 35px 0;
  }

  .landing-services li h4,
  .landing-about > div:nth-of-type(1) h3 {
    font-size: 16px;
  }

  .landing-services li p,
  .landing-download > div:nth-of-type(1) p,
  .landing-about > div:nth-of-type(1) p {
    font-size: 12px;
  }

  .landing-biodata > div:nth-of-type(1) h4 {
    margin: 30px 0;
    font-size: 20px;
  }

  .landing-services li h4::after {
    width: 23%;
    left: 38%;
  }

  .landing-download > div > div button {
    flex-direction: column;
  }

  .landing-biodata > div:nth-of-type(1) a,
  .landing-about > div:nth-of-type(1) button {
    font-size: 12px;
    letter-spacing: unset;
  }

  .landing-about > div:nth-of-type(2),
  .landing-about > div:nth-of-type(2) img,
  .landing-download > div:nth-of-type(2) img {
    width: 250px;
  }
}

/* New */
.otp {
  height: 85vh;
  background-image: linear-gradient(142deg, #9862c2, #5cc0de);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}

.otp .wrapper {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 20%);
}

.otp .wrapper > h3 {
  margin-top: 5%;
  font-size: 25px;
}

.otp .wrapper > h4 {
  margin: 2% 0 3%;
  font-size: 30px;
}

.otp .wrapper > p {
  font-size: 20px;
}

.otp form .form_group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.otp form .form_group input[type="text"] {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border: 1px solid #eee;
  font-size: 30px;
  width: 75px;
  height: 80px;
  margin: 10px;
  font-weight: 300;
  text-align: center;
  caret-color: transparent;
  outline-color: rgba(0, 0, 0, 0.5);
}

.otp form .form_group input[type="text"]:valid {
  border-color: #9862c2;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
}

.otp form .form_group button {
  font-size: 18px;
  text-transform: capitalize;
}

.otp form .form_group button:nth-of-type(1) {
  margin-right: 50px;
}

.otp form .error {
  color: #f00;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

@media only screen and (max-width: 996px) {
  .otp .wrapper > h3 {
    margin: 0 0 25px;
    font-size: 20px;
  }

  .otp .wrapper > h4 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .otp .wrapper > h3 {
    font-size: 16px;
  }

  .otp .wrapper > h4 {
    font-size: 20px;
  }

  .otp form .form_group input[type="text"] {
    width: 60px;
    height: 60px;
  }

  .otp form .error {
    font-size: 16px;
  }
}

@media only screen and (max-width: 420px) {

  .login-info-top > input {
    width: 70%;
  }
  .otp form .form_group input[type="text"] {
    width: 40px;
    height: 40px;
    margin: 5px;
  }

  .otp form .form_group button {
    font-size: 14px;
  }

  .otp .wrapper > h3 {
    font-size: 10px;
  }

  .otp .wrapper > h4 {
    font-size: 16px;
  }

  .otp form .error {
    font-size: 12px;
  }
}

@media only screen and (max-width: 290px) {

  .login-info-top > input {
    width: 66%;
  }
}

@media only screen and (max-width: 820px) {
  .login-right-side >  form {
    height: fit-content;
  }
  .login-info-top > input {
    width: 66%;
  }
}