.profile-dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f6f6f6;
  font-family: "Open Sans";
  overflow: scroll;
  height: 100%;
}

.profile-dashboard .section:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 5px;
  gap: 24%;
  padding: 10px;
}

.profile-dashboard .back-button {
  border: none;
  outline: none;
  background-color: transparent;
}
.profile-dashboard .back-button img {
  width: 24px;
}

.profile-dashboard h1.dashboard-heading {
  font-size: 18px;
  font-weight: 700;
  color: #1e2742;
}

.profile-dashboard .section:nth-child(2) {
  margin-bottom: 30px;
}

.profile-dashboard .stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
  height: 24px;
}

.profile-dashboard .stats h2 {
  font-size: 16px;
  color: #1e2742;
  font-weight: 400;
}

.profile-dashboard .information-button {
  border: none;
  outline: none;
  background-color: transparent;
}
.profile-dashboard .information-button img {
  width: 24px;
}

.profile-dashboard .stats-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  gap: 5%;
}
.profile-dashboard .stats-container div {
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  position: relative;
}
.profile-dashboard .stats-container div h3 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #1e2742;
}

.profile-dashboard .stats-container div p {
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  bottom: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  margin: 0;
}
.profile-dashboard .shortlisted-profiles-status,
.profile-dashboard .messages-status {
  height: 90px;
  margin-bottom: 5%;
}
.profile-dashboard .profile-views-status,
.profile-dashboard .sharing-requests-status {
  height: 111px;
}

.profile-dashboard .shortlisted-profiles-status {
  background-color: #fdd9df;
  width: 60%;
  color: #f11f45;
}
.profile-dashboard .messages-status {
  background-color: #fde7c7;
  width: 35%;
  color: #f6a01f;
}
.profile-dashboard .profile-views-status {
  background-color: #ffffff;
  width: 40%;
  color: #a4a4a4;
}

.profile-dashboard .sharing-requests-status {
  background-color: #c7e2fb;
  width: 55%;
  color: #1f8cf1;
}

.profile-dashboard .section:nth-child(3) {
  margin-bottom: 30px;
  border-top: 1px solid #dddddd;
}

.profile-dashboard .profile-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-top: 15px;
}

.profile-dashboard p {
  font-size: 15px;
  margin-bottom: 7px;
}

.profile-dashboard .profile-status h2 {
  font-size: 16px;
  color: #1e2742;
  font-weight: 400;
}

.profile-dashboard .profile-status h2 img {
  width: 24px;
  margin-left: 5px;
}

.profile-dashboard .next-button {
  border: none;
  outline: none;
  background-color: transparent;
}

.profile-dashboard .next-button img {
  width: 24px;
}

.profile-dashboard .percentage-container {
  background-color: #dddddd;
  border-radius: 12px;
  text-align: center;
  height: 24px;
  color: #ffffff;
}

.profile-dashboard .percentage-container p {
  background-color: #1f8cf1;
  border-radius: 12px;
  font-size: 14px;
  height: 24px;
}

.profile-dashboard .section:nth-child(4) {
  margin-bottom: 30px;
  border-top: 1px solid #dddddd;
}

.profile-dashboard .shortlisted-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 15px;
}

.profile-dashboard .shortlisted-heading h2 {
  font-size: 16px;
  font-weight: 400;
  color: #1e2742;
  margin: 0;
}

.profile-dashboard .view-all-button {
  border: none;
  background-color: transparent;
  color: #1f8cf1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 700;
}

.profile-dashboard .view-all-button img {
  width: 24px;
}

.profile-dashboard .shortlisted-profiles-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.profile-dashboard .profile-container {
  -ms-flex-preferred-size: calc(50% - 10px);
      flex-basis: calc(50% - 10px);
  margin-top: 20px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 8px;
  -webkit-box-shadow: 0px 4px 8px 0px #2f2f2f40;
          box-shadow: 0px 4px 8px 0px #2f2f2f40;
}

.profile-dashboard .profile-container img {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

.profile-dashboard .profile-container .profile-user-name,
.profile-dashboard .profile-container .user-age-city {
  font-size: 14px;
  font-weight: 700;
  color: #1e2742;
}

.profile-dashboard .profile-container .user-age-city {
  font-weight: 400;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  margin: 0;
}

.profile-dashboard .profile-container p {
  margin: 5px 0;
}

.profile-dashboard .profile-container .view-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #1f8cf1;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
}
