.contact-box {
  background-color: #03b0cb !important;
}

.contact-head {
  color: #03b0cb;
  font-size: 30px;
}

@media only screen and (max-width: 540px) {
  .contact-head {
    color: #03b0cb;
    font-size: 25px;
  }
}