.page_not_found {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#f1efef 50%, #dce2e2 50%);
  text-align: center;
}

.page_not_found .wrapper > h3 {
  padding-bottom: 10px;
  color: #dce2e2;
  font-size: 200px;
  font-weight: 700;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.page_not_found .page_not_found_content h4 {
  color: #0000b5;
  font-size: 50px;
  text-transform: capitalize;
}

.page_not_found .page_not_found_content p {
  margin-bottom: 30px;
  color: #0000b5;
  font-size: 20px;
  text-transform: capitalize;
}

.page_not_found .page_not_found_content .button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_not_found .page_not_found_content a {
  padding: 5px 20px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  transition: 0.5s ease-in;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .page_not_found .page_not_found_content a:hover {
    background-color: #dce2e2;
    color: #f48e8ede;
    box-shadow: 0 0 15px #fff;
  }
}

@media only screen and (max-width: 768px) {
  .page_not_found .wrapper > h3 {
    padding: 0;
    font-size: 150px;
  }

  .page_not_found .page_not_found_content h4 {
    font-size: 30px;
  }

  .page_not_found .page_not_found_content p {
    margin-bottom: 23px;
  }
}

@media only screen and (max-width: 480px) {
  .page_not_found .wrapper > h3 {
    font-size: 120px;
  }

  .page_not_found .page_not_found_content h4 {
    font-size: 22px;
  }

  .page_not_found .page_not_found_content p {
    font-size: 11px;
  }
}