.main-side-panel {
  position: relative;
  width: 80%;
  float: right;
  background-color: #fff;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.4s;
  animation: nnn 0.5s ease-in-out forwards;
}
@keyframes nnn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.main-side-panel div:nth-of-type(1){
  flex-basis: 10%;
}
.main-side-panel div:nth-of-type(1) span {
  padding-left: 20px;
  font-size: 24px;
}
.main-side-panel div:nth-of-type(2){
  flex-basis: 70%;
}
.main-side-panel div:nth-of-type(2) >ul{
  width: 100%;
  margin: 0;
  padding: 0;
}
.main-side-panel div:nth-of-type(2) >ul li {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 18px;
  color: #A4A4A4;
  align-items: center;
}
.main-side-panel div:nth-of-type(2) >ul li > span{
  flex-basis: 80%;
}
.main-side-panel div:nth-of-type(2) >ul li > span > span{
  color: #1E2742;
  padding-left: 10px;
}
.main-side-panel div:nth-of-type(2) >ul li:hover {
  background-color: #f6f6f6;
}
.main-side-panel div:nth-of-type(3){
  flex-basis: 20%;
  margin: 20px;
  border-top: 2px solid #DDDDDD;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  padding: 10px;
  color: red;
}
.main-side-panel div:nth-of-type(3) > span{
  height: 30px;
  display: flex;
  align-items: center;
}

.main-side-panel-icons{
  height: 20px;
  width: 20px;
}