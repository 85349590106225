.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: unset;
}
.profile >div {
  width: 100%;
}

.profile_details {
  padding: 2% 0;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
}

.profile .wrapper>h3 {
  margin-bottom: 1.5%;
  color: #03b0cb;
  font-size: 40px;
  text-transform: capitalize;
  text-align: center;
}

.profile_details>form {
  margin: 0 8%;
}

.form-group {
  display: flex;
}

.form-group>div {
  display: flex;
  flex-direction: column;
}

.form-group:last-child>div {
  flex-direction: unset;
}

.form-group:last-child>div:last-child {
  justify-content: flex-end;
}

.input-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-group:last-child {
  justify-content: space-between;
  align-items: center;
}

.form-group:not(:last-child) {
  margin-bottom: 2%;
}

.form-group>div:nth-of-type(1) {
  margin-right: 10%;
  flex-basis: 45%;
}

.form-group>div:nth-of-type(2) {
  flex-basis: 45%;
}

.form-group label {
  flex-basis: 35%;
  text-transform: capitalize;
  font-size: 19px;
}

.form-group span,
.form-group input {
  flex-basis: 65%;
}

.form-group input {
  padding: 2%;
  border: 0;
  border-bottom: 2px solid #000;
  outline: 0;
  border-radius: 5px;
  transition: 0.4s ease-in;
}

.form-group button:hover {
  background-color: #0000b5;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

.profile_details .error {
  padding: 1% 0;
  margin-bottom: 2%;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-radius: 30px;
}

.profile_details .errorMessage {
  margin-top: 4px;
  color: #800000;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.profile_details .saveclosebtns {
  display: flex;
  justify-content: space-between;
}

.profile_details .saveclosebtns button {
  padding: 6px 10px;
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.5s ease-in;
  border-radius: 5px;
}
.profile_details .saveclosebtns button:hover {
  background-color: #0000b5;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 995px) {
  .wrapper {
    width: 95%;
  }

  .profile .wrapper>h3 {
    margin-bottom: 2%;
    font-size: 35px;
  }

  .profile .profile_details h4 {
    font-size: 25px;
  }

  .form-group label {
    font-size: 15px;
  }

  .profile_details .error {
    font-size: 14px;
  }

  .profile_details .saveclosebtns button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .form-group>div:nth-of-type(1) {
    margin-right: 0;
  }

  .form-group:not(:last-child) {
    margin-bottom: 0;
  }

  .input-field,
  .mobile,
  .country,
  .material {
    margin-bottom: 2%;
  }

  .form-group:last-child {
    margin-top: 2%;
    flex-direction: unset;
  }
}

@media only screen and (max-width: 480px) {

  .input-field,
  .mobile,
  .country,
  .material {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }

  .profile .wrapper>h3 {
    font-size: 25px;
  }

  .profile .profile_details h4 {
    font-size: 18px;
  }

  .myprofile-wrapper {
    width: 98% !important;
  }

  .profile_details .saveclosebtns button {
    font-size: 12px;
  }
}