.terms_conditions .terms_content {
  padding: 3% 0;
}
.terms_conditions .terms_content p {
  font-size: 18px;
  text-align: justify;
}

.terms_conditions .terms_content h3 {
  font-size: 30px;
  color: #03b0cb;
}

.terms_conditions .terms_content div:not(:last-child) {
  margin-bottom: 3%;
}

@media only screen and (max-width: 540px) {
  .terms_conditions .terms_content p {
    font-size: 15px;
    text-align: justify;
  }

  .terms_conditions .terms_content h3 {
    font-size: 22px;
  }

  .terms .wrapper > h3 {
    font-size: 22px;
  }

  .user_welcome .wrapper > h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  .terms_conditions .terms_content p {
    font-size: 12px;
    text-align: justify;
  }

  .terms_conditions .terms_content h3 {
    font-size: 18px;
  }

  .terms .wrapper > h3 {
    font-size: 20px;
  }

  .user_welcome .wrapper > h3 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 240px) {
  .terms_conditions .terms_content p {
    font-size: 12px;
    text-align: justify;
  }

  .terms_conditions .terms_content h3 {
    font-size: 15px;
  }

  .terms .wrapper > h3 {
    font-size: 16px;
  }

  .user_welcome .wrapper > h3 {
    font-size: 10px;
  }
}