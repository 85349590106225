/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* BottomSheet.css */

.bottom-sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  z-index: 9999;
}

.bottom-sheet-content {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px;
  width: 100%;
  /* max-width: 500px; */
  /* for specific width un-comment this */
  height: 60%;
  max-height: 95%;
  overflow-y: scroll;
  position: relative;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-animation: slideShow 0.5s ease-in-out forwards;
  animation: slideShow 0.5s ease-in-out forwards;
}

@-webkit-keyframes slideShow {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes slideShow {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.bottom-sheet-content::-webkit-scrollbar {
  display: none;
}

.design-bar {
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
  user-select: none;
  /* Standard syntax */
  background-color: red;
  height: 70px;
  position: absolute;
  top: 0;
}

.drag-bar {
  cursor: -webkit-grab;
  cursor: grab;
  padding: 15px 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 105px;
  /* Adjust the height to match your desired size */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
  user-select: none;
  /* Standard syntax */
}

.drag-bar:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.bottomSheetContent {
  margin-top: 100px;
  /* Adjust the margin to create space below the Drag Bar */
  padding: 0 10px;
}

.aboutDetailsWrapper {
  padding-bottom: 35px;
}

.aboutTitleBar {
  font-family: 'Open Sans', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.aboutTitleIcon {
  padding: 0 10px 0 0;
  width: 30px;
  height: 20px;
}

.aboutTitle {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17px;
  vertical-align: baseline;
  color: #1E2742;
}

.aboutDetails {
  padding: 0 0 0 30px;
  color: #1e2742;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}

.bottomSheetHeader {
  padding: 20px 0 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aboutSideLine {
  width: 100%;
  height: 1px;
  background-color: #FAA9B7;
}

.bottomSheetHeaderAbout {
  font-family: 'Open Sans', sans-serif;
  color: #1E2742;
}

.bottomSheetCross {
  cursor: pointer;
}

.bottomSheetDetails {
  cursor: pointer;
  color: #1E2742;
}

.aboutViewDetailsBtn {
  width: 145px;
  height: 49px;
  margin: 15px 0 30px;
  padding: 15px 30px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  background-color: #1f8cf1;
  border: none;
  border-radius: 10px;
}