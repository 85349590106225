.interested-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 30px 0;
}
.interested-container .interested-left-container {
  flex-basis: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.interested-container .interested-left-container .interested-matches {
  margin: 0 10px;
}
.interested-icons {
  font-size: 1.5rem;
}
.interested-container .interested-left-container .interested-matches p {
  margin: 0;
}
.interested-container .interested-left-container h4 {
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
  background-color: #03b0cb;
}
.interested-container .interested-left-container .interested-matches > div {
  padding: 5px 20px;
  border: 1px solid #000;
  border-bottom: none;
}
.interested-container .interested-left-container .interested-matches > div:last-child {
  border-bottom: 1px solid #000;
}
.interested-container .interested-left-container .interested-search {
  margin: 0 10px;
}
.interested-container .interested-search .interested-search-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid #000;
}
.interested-container .interested-search .interested-search-content > input {
  margin: 0;
  padding: 5px 5px;
  width: 70%;
  border-radius: 0px;
  border: 1px solid #000;
  outline: none;
}
.interested-container .interested-left-container .interested-similar {
  margin: 0 10px;
}
.interested-container .interested-left-container .interested-similar .interested-similar-profiles {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px 20px;
  border: 1px solid #000;
  border-bottom: none;
}
.interested-container .interested-left-container .interested-similar > div:last-child {
  border-bottom: 1px solid #000;
}
.interested-container .interested-similar-profiles .similar-user-image {
  flex-basis: 30%;
  align-self: center;
}
.interested-container .interested-similar-profiles .similar-user-content {
  flex-basis: 70%;
  align-self: center;
}
.interested-container .interested-left-container .similar-user-content > p {
  margin: 0;
  font-size: 13px;
}
.interested-container .interested-right-container {
  flex-basis: 70%;
  width: 100%;
}
.interested-content {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  margin-bottom: 30px;
}
.interested-content .interested-content1-top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.interested-content .interested-content1-top > p {
  margin: 0;
  padding: 10px 0;
}
.interested-content .interested-content1-top > h4 {
  margin: 0;
  color: #0000b5;
  font-weight: 600;
}
.interested-content .interested-content2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.interested-content .interested-content2 .interested-content2-left {
  flex-basis: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.interested-content .interested-content2 .interested-content2-left button {
  width: 100%;
  align-self: center;
  cursor: pointer;
}
.interested-content .interested-content2 .interested-content2-left > img {
  width: 100%;
  height: 70%;
  border: 1px solid #000;
  padding: 20px 0;
}
.interested-content .interested-content2 .interested-content2-right {
  flex-basis: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.interested-content2-right .user-profile-interested {
  position: relative;
  border-bottom: 2px dashed #4e4b4b;
  padding: 10px 0;
}
.interested-content2-right .para-element-interested {
  padding: 10px 0 0 0;
}
.interested-content2-right .user-profile-interested .view-full {
  width: 100%;
  text-align: end;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}
.interested-content2-right .user-profile-interested .interested-name-element {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 3px;
}
.interested-content2-right .user-online-interested {
  padding: 0 0 10px 0;
  border-bottom: 2px dashed #767474;
}
.interested-content2-right .text-button-interested {
  background: none;
  border: none;
  text-decoration: underline;
}
.interested-content2-right .text-button-interested > span {
  color: #0000b5;
}
.interested-content2-right p {
  margin: 0;
  padding: 2px 0;
}
.interested-side-menu {
  display: none;
  cursor: pointer;
  padding: 5px;
  border: none;
  color: #0000b5;
  background-color: #03b0cb;
  margin-top: 20px;
}

@media only screen and (max-width: 1024px) {
  .interested-container .interested-left-container .interested-matches > div {
    padding: 5px 10px;
  }
  .interested-container .interested-left-container .interested-matches p {
    font-size: 12px;
  }
  .interested-container .interested-search .interested-search-content {
    flex-direction: column;
    gap: 5px;
    padding: 5px 10px;
  }
  .interested-container .interested-search .interested-search-content > input {
    width: 95%;
    font-size: 10px;
  }
  .interested-container .interested-search .interested-search-content > button {
    font-size: 12px;
  }
  .interested-container .interested-left-container .interested-similar .interested-similar-profiles {
    padding: 5px 10px;
    gap: 5px;
  }
  .interested-container .interested-left-container .similar-user-content > p {
    font-size: 10px;
  }
  .interested-container .interested-left-container h4 {
    font-size: 15px;
  }
  .interested-content .interested-content1-top > h4 {
    font-size: 15px;
  }
  .interested-content .interested-content2 .interested-content2-left button {
    font-size: 12px;
    width: 100%;
  }
  .interested-content .interested-content2 .interested-content2-left {
    flex-basis: 50%;
    padding: 10px;
  }
  .interested-content .interested-content2 .interested-content2-right {
    padding: 10px;
  }
  .interested-content2-right .user-profile-interested .interested-name-element {
    font-size: 20px;
  }
  .interested-content2-right p {
    padding: 1px 0;
    font-size: 12px;
  }
  .interested-content2-right .text-button-interested {
    font-size: 12px;
  }
  .interested-content2-right .para-element-interested {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .interested-container {
    padding: 20px 0;
  }
  .interested-content .interested-content2 {
    flex-direction: column;
  }
  .interested-content .interested-content2 .interested-content2-left {
    padding: 0;
    gap: 10px;
  }
  .interested-content .interested-content2 .interested-content2-left img {
    width: 60%;
    align-self: center;
    padding: 0;
  }
  .interested-content .interested-content2 .interested-content2-left button {
    width: 60%;
  }
  .interested-content .interested-content2 .interested-content2-right {
    padding: 10px 20px;
  }
  .interested-container .interested-left-container .interested-matches > div {
    padding: 5px 0px;
  }
  .interested-container .interested-left-container h4 {
    font-size: 13px;
  }
  .interested-content .interested-content1-top > h4 {
    font-size: 13px;
  }
  .interested-content2-right .user-online-interested {
    padding: 0;
  }
  .interested-content2-right .user-profile-interested {
    padding: 0;
  }
  .interested-content2-right .para-element-interested {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .interested-side-menu {
    display: block;
  }
  .interested-container.active {
    position: relative;
    z-index: 100;
  }
  .interested-container .interested-left-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: aliceblue;
    animation: sideBarAnimate 0.3s ease-in;
  }
  .interested-container .interested-left-container.active {
    display: block;
  }
  .interested-container .interested-left-container.active > div {
    padding: 10px 0;
  }
  .interested-container .interested-right-container {
    flex-basis: 100%;
  }
  .interested-container .interested-left-container h4 {
    font-size: 20px;
  }
  .interested-container .interested-left-container .interested-matches p {
    font-size: 16px;
  }
  .interested-container .interested-search .interested-search-content {
    flex-direction: row;
  }
  .interested-container .interested-search .interested-search-content > input {
    justify-content: space-evenly;
    width: 70%;
    font-size: 16px;
  }
  .interested-container .interested-left-container .similar-user-content > p {
    font-size: 16px;
  }
}

@keyframes sideBarAnimate {
  0% {
    left: -520px;
  }
  25% {
    left: -260px;
  }
  50% {
    left: -130px;
  }
  75% {
    left: -65px;
  }
  100% {
    left: 0;
  }
}