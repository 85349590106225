@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper-heading {
  max-width: 1360px;
  width: 87.13%;
  margin: 0 auto;
}

.wrapper-content {
  max-width: 1360px;
  width: 87.13%;
  margin: 0 auto;
  padding: 3% 0;
}

.body .user-welcome {
  padding: 10px 0;
  background-color: #03b0cb;
  color: #fff;
}

.user-welcome h3 {
  font-size: 20px;
}

.page-heading {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: capitalize;
}

.page-heading-text {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.content p {
  font-size: 18px;
  text-align: justify;
}

.content .content-heading h4 {
  color: #03b0cb;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
}

.light-button {
  padding: 0.5% 4%;
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.5s ease-in;
  border-radius: 5px;
}

.light-button:hover {
  background-color: #0000b5;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

.dark-button {
  padding: 1% 6%;
  outline: 0;
  border: 0;
  background-color: #0000b5;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.1s ease-in;
  border-radius: 5px;
}

.details-heading {
  padding-bottom: 30px;
  text-align: center;
  color: #03b0cb;
}

@media only screen and (max-width: 540px) {
  .wrapper-heading > h3 {
    font-size: 18px;
  }

  .content p {
    font-size: 15px;
  }

  .content .content-heading h4 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 340px) {
  .wrapper-heading > h3 {
    font-size: 16px;
  }

  .content .content-heading h4 {
    font-size: 15px;
  }
}
