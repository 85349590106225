* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.wrapper {
  max-width: 1360px;
  margin: 0 auto;
}

.wrapper .content {
  display: flex;
  margin-bottom: 25px;
}

.wrapper .content .userContent {
  padding: 20px;
  border: 2px solid black;
}

.wrapper .content .userDetails {
  font-size: 20px;
}

.wrapper .content .userSummary {
  padding: 20px;
}

.wrapper .content .userSummary p {
  text-align: justify;
}

.sliderHead {
  font-size: 30px;
  padding: 10px 0;
  border-top: 1px solid;
}

.nav ul {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nav ul li {
  list-style: none;
  padding-right: 20px;
}

.nav ul li a {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .content .userContent img {
  width: 300px;
  height: 300px;
}

.slider1 {
  padding: 8px;
}

.sliderCard {
  /* background-repeat: "no-repeat";
  background-size: "cover";
  background-position: "center"; */
  /* margin: 0 auto; */
  /* border: 1px solid #00ffff; */
  width: 100%;
  height: 550px;
  border-radius: 16px;
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
}

h5>span:first-child {
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, .3);
  letter-spacing: 1.3px;
  border-radius: 20px;
  text-shadow: 1px 1px 5px #000;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
  font-size:14px
}

@media (max-width: 45rem) {
  .sliderCard {
    height: 400px;
  }
}

.slider-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.sliderCardText {
  position: absolute;
  bottom: 60px;
  padding: 0 0 10px 20px;
  color: white;
}

.icon {
  display: flex;
  transform: translate(10%, -25%);
  height: 35px;
  width: 35px;
  margin: 0 20px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: transform 0.5s;
  filter: drop-shadow(5px 5px 10px #b3b3b3);
}

.icon:hover {
  transform: translateY(2px);
}

.like {
  color: #33df3c;
}

.favourite {
  color: rgb(255, 0, 162);
}

.slick-prev,
.slick-next {
  background: #0000b5 !important;
}

.slick-dots {
  bottom: 10px !important;
  display: none !important;
}

.slick-prev {
  left: -35px !important;
}

/* ICON BUTTON */

.sliderCard .icon-button {
  position: relative;
  display: flex;
  bottom: 60px;
  justify-content: space-around;
}

.sliderCard .icon-button > span {
  padding: 8px 12px;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease-in;
}

.sliderCard .icon-button > span:nth-of-type(1) {
  border: 2px solid#e1c05a;
}

.sliderCard .icon-button > span:nth-of-type(1) > svg {
  color: #e1c05a;
}

.sliderCard .icon-button > span:nth-of-type(2) {
  border: 2px solid#dd4c61;
}

.sliderCard .icon-button > span:nth-of-type(2) > svg {
  color: #dd4c61;
}

.sliderCard .icon-button > span:nth-of-type(3) {
  border: 2px solid#44c8ff;
}

.sliderCard .icon-button > span:nth-of-type(3) > svg {
  color: #44c8ff;
}

.sliderCard .icon-button > span:nth-of-type(4) {
  border: 2px solid#52f6b9;
}

.sliderCard .icon-button > span:nth-of-type(4) > svg {
  color: #52f6b9;
}

.sliderCard .icon-button > span:nth-of-type(5) {
  border: 2px solid#b155dd;
}

.sliderCard .icon-button > span:nth-of-type(5) > svg {
  color: #b155dd;
}

@media only screen and (max-width: 900px) {
  .Slidertext h3 {
    font-size: 20px;
  }

  .Slidertext h6 {
    font-size: 15px;
  }
}


@media only screen and (max-width: 768px) {
  .wrapper .content .userContent img {
    width: 200px;
    height: 200px;
  }

  .nav ul {
    flex-direction: column;
    align-items: flex-end;
  }

  .slick-prev {
    left: -25px !important;
  }

  .slick-next {
    right: -15px !important;
  }

  .Slidertext h3 {
    font-size: 20px;
  }

  .Slidertext h6 {
    font-size: 15px;
  }
  .sliderCard .icon-button > span:nth-of-type(1):hover,
  .sliderCard .icon-button > span:nth-of-type(2):hover,
  .sliderCard .icon-button > span:nth-of-type(3):hover,
  .sliderCard .icon-button > span:nth-of-type(4):hover,
  .sliderCard .icon-button > span:nth-of-type(5):hover {
    border: 2px solid transparent;
  }
}

@media only screen and (max-width: 645px) {
  .userSummary p {
    font-size: 12px;
  }

  .Slidertext h3 {
    font-size: 18px;
  }

  .Slidertext h6 {
    font-size: 16px;
  }

  .icon {
    margin: 0 18px;
  }
}

@media only screen and (max-width: 540px) {
  .content {
    flex-direction: column;
  }

  .wrapper .content .userSummary {
    padding: 0;
    padding-top: 20px;
  }

  .userDetails p {
    font-size: medium;
  }

  .Slidertext h3 {
    font-size: 16px;
  }

  .Slidertext h6 {
    font-size: 14px;
  }

  .userContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    margin: 0 15px;
  }

  .sliderCard .icon-button > span {
    padding: 3px 5px;
  }
}

@media only screen and (max-width: 380px) {
  .wrapper .content .userContent img {
    width: 140px;
    height: 140px;
  }

  .wrapper .content .userContent {
    padding: 5px;
  }

  .Slidertext h3 {
    font-size: 20px;
  }

  .Slidertext h6 {
    font-size: 18px;
  }

  .userDetails p {
    font-size: small;
  }

  .slider h3,
  .slider p {
    font-size: medium;
  }

  .icon {
    margin: 0 10px;
  }
}
