.personal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.personal .wrapper {
  width: 100% !important;
}

.personal_details {
  padding: 2% 0;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
}

.personal .wrapper > h3 {
  margin-bottom: 1.5%;
  color: #03b0cb;
  font-size: 40px;
  text-transform: capitalize;
  text-align: center;
}

.personal .personal_details h4 {
  margin-bottom: 1%;
  color: #03b0cb;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
}

.personal_details > form {
  margin: 0 8%;
}

.form-group {
  display: flex;
}
.text-box {
  width: 90%;
}
.form-group:last-child {
  justify-content: space-between;
  align-items: center;
}

.form-group:not(:last-child) {
  margin-bottom: 2%;
}

.form-group > div {
  display: flex;
  flex-direction: column;
}

.form-group:last-child > div {
  flex-direction: unset;
}

.form-group:last-child > div:last-child {
  justify-content: flex-end;
}

.form-group > div:nth-of-type(1) {
  margin-right: 10%;
  flex-basis: 45%;
}

.form-group > div:nth-of-type(2) {
  flex-basis: 45%;
}

.input-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-box-label {
  font-size: 19px;
  text-transform: capitalize;
}
form > .about {
  margin-bottom: 2%;
}
.form-group label {
  flex-basis: 35%;
  font-size: 19px;
  text-transform: capitalize;
}

.form-group input {
  flex-basis: 65%;
}

.form-group select {
  flex-basis: 65%;
  width: 60%;
}

.form-group input,
.form-group select {
  padding: 2%;
  border: 0;
  border-bottom: 2px solid #000;
  outline: 0;
  border-radius: 5px;
  transition: 0.4s ease-in;
}

.form-group button:hover {
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

.personal_details .error {
  padding: 1% 0;
  margin-bottom: 2%;
  text-align: center;
}

.selectActive {
  display: none;
}

.personal_details .errorMessage {
  margin-top: 4px;
  color: #800000;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.personal .warning {
  margin: 10px 0;
  color: #800000;
}

.personal .saveclosebtns {
  display: flex;
  justify-content: space-between;
}

.personal .saveclosebtns button {
  /* padding: .5% 4%; */
  padding: 6px 10px;
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.5s ease-in;
  border-radius: 5px;
}
.personal .saveclosebtns button:hover {
  background-color: #0000b5;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 995px) {
  .wrapper {
    width: 95%;
  }

  .personal .wrapper > h3 {
    margin-bottom: 2%;
    font-size: 35px;
  }

  .personal .personal_details h4 {
    font-size: 25px;
  }
  .input-field > .text-box-label {
    font-size: 15px;
    font-weight: 500;
  }
  .form-group label {
    font-size: 15px;
    font-weight: 500;
  }

  .form-group select {
    flex-basis: 67%;
  }

  .personal_details .error {
    font-size: 14px;
  }

  .personal .warning {
    font-size: 14px;
  }
  .personal .saveclosebtns button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .personal .wrapper > h3 {
    margin-bottom: 2%;
    font-size: 35px;
  }

  .personal .personal_details h4 {
    font-size: 25px;
  }
  .input-field > .text-box-label {
    font-size: 15px;
  }
  .form-group label {
    font-size: 15px;
  }

  .form-group select {
    flex-basis: 67%;
  }

  .personal_details .error {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .input-field,
  .mobile,
  .country,
  .material {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }

  .form-group select {
    width: auto;
  }

  .personal .wrapper > h3 {
    font-size: 25px;
  }

  .personal .personal_details h4 {
    font-size: 18px;
  }

  .personal .warning {
    font-size: 12px;
  }
  .personal .saveclosebtns button {
    font-size: 12px;
  }
  .personal_details > form {
    margin: 0 4%;
  }
}