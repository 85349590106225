/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.edit-preference-mobile {
    height: 91vh;
    position: relative;
}

.edit-preference-mobile .edit-preference-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 30px 10px 20px;
}

.edit-preference-mobile .cross-btn {
    position: absolute;
    left: 10px;
}

.edit-preference-mobile h5 {
    font-size: 18px;
    margin-bottom: 0;
}

.edit-preference-mobile .edit-preference-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 20px;
}

.edit-preference-mobile .edit-preference-icons>span {
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 20px;
}

.edit-preference-mobile .edit-preference-icons>div {
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
}

.edit-preference-icons .red {
    color: #F11F45;
}

.edit-preference-mobile .edit-preference-icons .space-between {
    border: 1px solid #DDDDDD;
}

.edit-preference-content {
    max-height: 72%;
    padding: 20px 20px 10px;
    overflow-y: scroll;
}

.edit-preference-content>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.edit-preference-content>div>h4 {
    font-size: 18px;
    font-weight: bold !important;
    margin-bottom: 0;
}

.edit-preference-content>div:first-child span {
    font-size: 16px;
    color: #F11F45;
    font-weight: bold;
}

.edit-preference-mobile .save-next-btn {
    width: 100%;
}

.edit-preference-mobile .save-next-btn button {
    width: 50%;
    border: none;
    padding: 10px 0;
    border: 1px solid #A4A4A4;
    height: 100%;
}

.edit-preference-mobile .save-next-btn .save-btn {
    color: #ffffff;
    background-color: #1F8CF1;
    border: 1px solid #1F8CF1;
}

.edit-preference-content .edit-basic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.edit-preference-content .edit-basic>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
}

.edit-preference-content .edit-basic>div:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
}

.edit-preference-content .edit-basic>div>label {
    font-weight: 600;
}

.edit-preference-content .edit-basic>div>input,
.edit-preference-content .edit-basic>div>select,
.edit-preference-content .edit-basic>div>div>select {
    padding: 5px 0;
    margin-top: 5px;
    border: none;
    background: none;
    border-bottom: 1px solid #1E2742;
    outline: none;
}

.edit-preference-content .edit-basic>div .mypreference-radio {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    padding: 10px 0;
}

.edit-preference-content .edit-basic>div .mypreference-radio input:checked+label {
    background-color: #C7E2FB;
    border: none;
}

.edit-preference-content .edit-basic>div .mypreference-radio>label {
    border: 1px solid #A4A4A4;
    border-radius: 17px;
    padding: 5px 20px;
}

.edit-preference-content .edit-basic>div>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.edit-preference-content .edit-basic>div>div>select {
    width: 40%;
}

@media only screen and (max-width: 350px) {
    .edit-preference-mobile h5 {
        font-size: 16px;
    }

    .edit-preference-content>div>h4 {
        font-size: 14px;
    }

    .edit-preference-content>div:first-child span {
        font-size: 12px;
    }
}