.shortlisted-content {
  display: flex;
}
.shortlisted-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.shortlisted-boxes {
  width: 30%;
  padding: 10px;
  margin: 0 2px;
}
.shortlisted-boxes ul {
  border: 2px solid black;
  padding: 0;
}
.shortlisted-boxes li {
  font-size: 17px;
  text-transform: capitalize;
  text-decoration: none;
  list-style-type: none;
  padding: 0 5px;
  border-bottom: 1px solid black;
  width: 100%;
}
.shortlisted-boxes .first-li {
  text-align: center;
  background-color: #03b0cb;
  color: white;
}
.shortlisted-users {
  border: 2px solid black;
  margin: 5px;
  padding: 10px;
}
.shortlisted-users .user-profile-shortlisted {
  display: flex;
}
.shortlisted-users h5 {
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.user-img-shortlisted {
  width: fit-content;
  display: flex;
  flex-direction: column;
}
.user-img-shortlisted > img {
  width: 350px;
  border: 4px solid black;
  border-radius: 5px;
}
.user-img-shortlisted > button {
  margin-top: 5px;
}
.user-profile-shortlisted {
  display: flex;
  padding: 10px;
}
.user-info-shortlisted {
  padding: 5px 20px;
  width: 100%;
}
.user-info-shortlisted > h6 {
  padding: 0 0 5px;
  border-bottom: 4px solid black;
  border-bottom-style: dashed;
  width: 100%;
}
.shortlisted-bullets {
  border-bottom: 4px solid black;
  border-bottom-style: dashed;
  width: 100%;
}
.summary-shortlisted {
  padding: 5px 0;
}
.search-bar-shortlisted {
  border: 2px solid black;
  margin-bottom: 1rem;
}
.search-bar-shortlisted input {
  width: 50%;
  border: 1px solid black;
  margin-right: 2px;
}
.search-bar-shortlisted .first-li {
  font-size: 18px;
}
.search-bar-shortlisted .search-shortlisted {
  padding: 0 5px;
}
.search-bar-shortlisted .search-shortlisted > .search-button-shortlisted {
  outline: 0;
  border: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.1s ease-in;
  border-radius: 5px;
  height: 40px;
  width: 80px;
}
.similar-profiles {
  border: 2px solid black;
}

@media only screen and (max-width: 990px) {
  .user-img-shortlisted > img {
    width: 250px;
    border: 4px solid black;
    border-radius: 5px;
  }
  .shortlisted-boxes li {
    font-size: 16px;
  }
  .search-bar-shortlisted .first-li {
    font-size: 16px;
  }
  .search-bar-shortlisted input {
    height: 30px;
  }
  .search-bar-shortlisted .search-shortlisted > .search-button-shortlisted {
    font-size: 15px;
    height: fit-content;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 890px) {
  .search-bar-shortlisted .search-shortlisted {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .search-bar-shortlisted input {
    width: 80%;
  }
  .search-bar-shortlisted .search-button-shortlisted {
    margin-top: 5px;
  }
  .user-img-shortlisted > img {
    width: 200px;
    border: 4px solid black;
    border-radius: 5px;
  }
  .shortlisted-boxes li {
    font-size: 14px;
  }
  .search-bar-shortlisted .first-li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 745px) {
  .shortlisted-content {
    flex-direction: column !important;
  }
  .shortlisted-boxes {
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
  }
  .shortlisted-menu li {
    font-size: 20px;
  }
  .search-bar-shortlisted.first-li {
    font-size: 20px;
  }
  .shortlisted-users > h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 540px) {
  .user-profile-shortlisted {
    flex-direction: column;
  }
  .user-img-shortlisted > img {
    width: 250px;
    border: 4px solid black;
    border-radius: 5px;
  }
  .user-img-shortlisted {
    margin: auto;
  }
  .shortlisted-menu li {
    font-size: 16px;
  }
  .search-bar-shortlisted.first-li {
    font-size: 16px;
  }
  .shortlisted-users > h5 {
    font-size: 15px;
  }
  .search-bar-shortlisted {
    width: 40%;
  }
  .search-bar-shortlisted > .first-li {
    margin: 0;
  }
  .search-shortlisted > input {
    width: 50%;
  }
}
@media only screen and (max-width: 410px) {
  .shortlisted-menu li {
    font-size: 13px;
  }
  .search-shortlisted > input {
    height: 20px;
  }
  .search-shortlisted {
    margin: 0;
  }
  .search-bar-shortlisted > .first-li {
    font-size: 13px;
  }
  .shortlisted-users > h5 {
    font-size: 13px;
  }
  .user-img-shortlisted > img {
    width: 175px;
    border: 4px solid black;
    border-radius: 5px;
  }
  .search-bar-shortlisted .search-shortlisted > .search-button-shortlisted {
    font-size: 12px;
    width: fit-content;
    padding: 2px;
  }
}