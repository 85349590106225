.main-preview-mobile {
  height: 80vh;
  position: relative;
  overflow-y: scroll;
}

.preview-mobile {
  padding-top: 40px;
}

.preview-mobile-menu img {
  float: right;
  padding-top: 15px;
  padding-right: 15px;
}

.preview-mobile>h5 {
  padding: 10px;
  margin-bottom: 0;
  font-size: 18px;
}

.preview-mobile .profile-mobile {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.preview-mobile .profile-mobile ul {
  margin-bottom: 0;
}

.preview-mobile .profile-mobile ul li {
  list-style-type: disc;
}

.profile-mobile .profile-image-mobile {
  margin: auto 0;
  position: relative;
}

.profile-image-mobile>div {
  position: absolute;
  background-color: #fff;
  border: 1px solid rgb(182, 180, 180);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: 5px;
  bottom: 5px;
  font-size: 18px;
}

.profile-mobile .profile-image-mobile img {
  width: 109px;
  height: 109px;
  border-radius: 50%;
}

.preview-mobile .preview-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
}

.preview-mobile .preview-btns button {
  /* all: unset; */
  padding: 6px 17px;
  border-radius: 8px;
  cursor: pointer;
}

.preview-mobile .preview-btns button:first-child {
  border: 1px solid #a4a4a4;
}

.preview-mobile .preview-btns button:first-child {
  background-color: #1f8cf1;
  color: #fff;
}

.mobile-multiple-img {
  padding: 10px 0 15px 10px;
  border-bottom: 1px solid #dddddd;
}

.mobile-multiple-img h5 {
  padding: 0 0 15px 0;
  margin-bottom: 0;
  font-size: 18px;
}

.mobile-multiple-img>div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: scroll;
}

.mobile-multiple-img>div img {
  border-radius: 5px;
  height: 120px !important;
}

.mobile-preferences {
  padding: 10px;
  
  overflow-y: scroll;
  height: max-content;
}

.mobile-preferences-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
}

.mobile-preferences-head h5 {
  font-size: 18px;
  margin-bottom: 0;
}

.mobile-preferences-head button {
  all: unset;
  font-weight: bold;
  color: #1f8cf1;
}

.mobile-preferences-content>div:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.mobile-preferences-content>div {
  padding: 10px 0 0;
}

.mobile-preferences-content div div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding-bottom: 10px;
}

.mobile-preferences-content div div:first-child span {
  flex-basis: 10%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-preferences-content div div:first-child span:first-child {
  color: red;
}

.mobile-preferences-content div div:last-child span:first-child {
  font-size: 15px;
}

.mobile-preferences-content div div:first-child h5 {
  flex-basis: 80%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

.mobile-preferences-content ul {
  padding-left: 3rem;
}
.profile-preview-slider {
  position: fixed;
  z-index: 110;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  top: 0;
}

.profile-preview-slider .slider {
  width: 90%;
  height: 55%;
}

.editMyProfile_page,
.editMyProfileDetails {
  width: 100%;
  height: 90vh;
  z-index: 100;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
}

.editSideMenuPanel {
  width: 100%;
  height: 100vh;
  z-index: 100000;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.2);
}

.main-preview-mobile .profile-dashboard {
  border: 1px solid #DDDDDD;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
}

.main-preview-mobile .profile-dashboard img {
  width: 14px;
  margin-right: 5px;
}

.dashboard-wrapper {
  position: absolute;
  top: 0;
  height: 85vh;
}

@media only screen and (max-width: 365px) {
  .preview-mobile .preview-btns {
    padding: 5px;
  }

  .preview-mobile .preview-btns button {
    padding: 3px 8px;
  }

  .preview-mobile .preview-btns button {
    font-size: 14px;
  }
}