.body .question {
  padding: 3% 4%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, rgb(17 17 26 / 10%) 0 8px 24px,
    rgb(17 17 26 / 10%) 0 16px 56px;
  margin-top: 3%;
}

.body .question>h3 {
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: #0000b5;
  font-size: 30px;
}

.main-heading {
  padding: 1% 0 1% 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #03b0cb;
  border-radius: 10px;
  transition: 0.5s ease-in;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.main-heading:not(:first-child) {
  margin-top: 2%;
}

.main-heading h4 {
  margin-left: 3%;
  color: white;
}

.main-heading p {
  padding: 0.6% 1% 1%;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  box-shadow: rgb(17 17 26 / 10%) 0 4px 16px, rgb(17 17 26 / 10%) 0 8px 24px,
    rgb(17 17 26 / 10%) 0 16px 56px;
}

.answer {
  padding: 4% 2%;
  font-size: 18px;
  font-weight: 400;
  background-color: white;
  line-height: 1.7;
  color: rgba(40, 37, 53);
  text-align: justify;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .main-heading:hover {
    background-color: #0000b5;
  }
}

@media only screen and (max-width: 991px) {
  .body .question>h3 {
    margin-bottom: 5%;
  }

  .main-heading:not(:first-child) {
    margin-top: 5%;
  }

  .main-heading h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .body .wrapper {
    width: 95%;
  }

  .body .question {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: 0 0 0 0 transparent;
  }

  .answer {
    padding: 2%;
    background-color: #fff;
    font-size: 13px;
  }

  .main-heading p {
    padding: 0.4% 1% 1%;
    margin-top: 2%;
  }

  .main-heading h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .body .question>h3 {
    font-size: 18px;
  }

  .main-heading h4 {
    font-size: 14px;
  }

  .answer {
    font-size: 12px;
    line-height: unset;
  }
}