@import url("https://fonts.googleapis.com/css?family=Sail");
.modal-container .main-modal-header .left-side .slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}

.modal-container .main-modal-header .left-side .slideshow .slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.modal-container .main-modal-header .left-side .slideshow .slideshowSlider .slide {
  display: inline-block;
  height: 200px;
  width: 100%;
  border-radius: 4px;
}

.headerCompWrapper {
  margin-bottom: 15px;
}

.headerContainer {
  height: 58px;
  padding: 30px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo{
  font-size: 21.33px;
  color: #cd373e;
  font-family: "Sail";
}
.login{
  font-size: 14px;
  color: #1f8cf1;
  font-weight: 600;
}

.header-container{
  margin-top: 20px;
  padding:10px 10px 0 ;
  display: none;
  justify-content: space-between;
  align-items: center;
}
.header-loginmenu{
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Flex Colm 1 */
.headerFlexColumn1 {
  width: 25%;
}

/* Flex Colm 2 */
.headerFlexColumn2 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerMiddleColIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerMiddleColIcon>a {
  padding: 7px 20px;
  font-size: 30px;
  color: black;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerHeartSearchIcon>a {
  padding: 7px 9px;
}

.headerSearchBar {
  width: 60%;
  background-color: #e4e6eb8f;
  border-radius: 10rem;
  padding: 1px 0;
  display: flex;
  align-items: center;
  transition: width 0.3s;
}

.headerSearchBar.expanded {
  animation: increaseSize 0.3s;
}

@keyframes increaseSize {
  from {
    width: 0;
  }

  to {
    width: 60%;
  }
}

.headerSearchBar>input {
  width: 100%;
  height: 38px;
  margin-left: 6px;
  border: none;
  border-radius: 25px;
  padding: 5px 10px;
  background-color: transparent;
  color: black;
}

.headerSearchBar>input:focus {
  outline: none;
}

/* Flex Colm 3 */
.headerFlexColumn3 {
  width: 25%;
}

/* Mobile Search Bar */
.mobileViewSearchBar {
  display: none;
  padding: 8px 7px 0;
  position: relative;
}

.mobileViewSearchBar>input {
  width: 100%;
  border: 0.8px solid #ddd;
  /* border-width: 1px 0 2.5px 0; */
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 18px;
  background-color: #f0f1f4;
}

.mobileViewSearchBar>input:focus {
  outline: none;
}

.mobileViewSearchBar>input::placeholder {
  font-weight: 600;
  color: #979494;
}

.mobileViewSearchBar>button {
  width: 80px;
  height: 38px;
  border: none;
  border-radius: 0 30px 30px 0;
  background-color: #ddd;

  position: absolute;
  top: 18%;
  right: 1%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileViewSearchBar>button>svg {
  font-size: 28px;
  color: #800125;
}

@media only screen and (max-width: 1000px) {
  .headerFlexColumn1 {
    width: 14%;
  }
}

/* disable search */
@media only screen and (max-width: 870.50px) {
  .mobileViewSearchBar {
    display: block;
    width: 100%;
  }

  .headerSearch {
    display: none;
  }
}

/* Medium Screen */
@media (min-width: 480px) and (max-width: 870px) {
  .headerFlexColumn1 {
    width: 25%;
  }

  .headerFlexColumn2 {
    width: 33%;
  }

  .headerFlexColumn3 {
    width: 33%;
  }

}

/* Small Screen */
@media only screen and (max-width: 570px) {

  .headerFlexColumn2 {
    width: 100%;
    padding: 10px 7px;
    background-color: #e4e6ebff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2001;
  }

  .headerFlexColumn3 {
    width: 40%;
  }

  .mobileViewSearchBar {
    display: block;
    width: 100%;
  }
}

/*  */
.headerNavRightSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerNavRightSection>a {
  margin: 0 10px 0 0;
}

.headerAccIconDiv {
  width: 42px;
  height: 42px;
  border-radius: 30px;
  background-color: #e4e6eb;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 20px;
}

.headerAccIconDiv>.headerAccProfileIcon {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.headerAccLogin {
  width: 115px;
  border-radius: 35px;
  padding: 5px 5px 5px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e6671;
  color: #ffffff;
}

.headerAccLogin:hover {
  background-color: #417177;
}

.headerAccLogin:first-child {
  font-size: 17px;
  font-weight: 900;
  color: #ffffff;
}

.headerAccLogin>.headerAccIconDiv {
  background-color: #659aa1;
}

.headerAccLogin>.headerAccIconDiv>svg {
  font-size: 20px;
  color: white;
}

/*  */
.smallMidDevice {
  display: none;
}

.headerDropConatiner {
  border-radius: 20px 20px 0 0;
  margin: 0 10px 0 0;
}

.header-dropdown {
  border-radius: 40px 40px 20px 20px;
  padding: 7px;
  background-color: #6577B3;
  box-shadow: 1.2px 1.1px 2px 0.1px #6577B3;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: absolute;
  right: 4px;
  top: 43px;
  z-index: 2000;
}

.header-dropdown>a:not(:last-child) {
  margin-bottom: 8px;
}

/*  */
.mobileNavSection>div>a>img {
  width: 50px;
}

.mobileNavSection>div>a {
  display: flex;
}

.mobileNavSection {
  display: none;
}

/*  */

@media only screen and (max-width: 720px) {
  .smallMidDevice {
    position: relative;
    display: flex;
  }

  .headerNavMid {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 870px) {
  .headerNavIconDiv {
    width: 89px;
    padding: 8px 20px 8px 20px;
  }
}

/*  */
@media only screen and (max-width: 550px) {
  .headerNavSection {
    display: none;
  }

  .mobileNavSection {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 3px 1px #ddd;
    z-index: 2000;
  }

  .mobileNavSection>a {
    font-size: 30px;
    color: black;
  }

  .headerNavIconLink {
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* -------------------------------------- */
.modal-container {
  width: 100%;
  height: 99vh;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: transparent;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.main-modal-header {
  width: 810px;
  height: 437px;
  z-index: 1000;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.left-side {
  width: 50%;
  padding: 30px 2%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 1;
  background-color: #b0e0e6;
}

.left-side>h3 {
  padding-bottom: 20px;
  border-bottom: 1px solid #dc143c;
  color: #dc143c;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
}

.left-side img {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.left-side>div:nth-of-type(1),
.left-side>div:nth-of-type(2)>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-side>div:nth-of-type(1) h3,
.left-side>div:nth-of-type(2)>div h3 {
  font-size: 14px;
  font-weight: 600;
}

.left-side>div:nth-of-type(1) h3 {
  width: 190px;
}

.left-side>div:nth-of-type(2)>div h3 {
  width: 260px;
}

.left-side>div:nth-of-type(2) {
  display: flex;
}

.left-side>div:nth-of-type(2)>div {
  flex: 1;
}

.left-side>div:nth-of-type(2)>div:nth-of-type(1) {
  margin-right: 5%;
}

.right-side {
  padding: 10px 2% 10px;
  flex: 1;
  position: relative;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right-side>p {
  padding-top: 0px;
  border-top: 1px solid #000;
  margin-bottom: 0px;
  position: absolute;
  bottom: 0;
  font-size: 11px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
  padding: 0 0 2% 0;
}

.notification {
  position: relative;
  cursor: pointer;
}

.notification-counter {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: 0;
  font-size: 14px;
  text-align: center;
  background-color: #03b0cb;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
}

.notification-content {
  position: absolute;
  width: 600px;
  top: 65px;
  right: 50px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 5px;
  max-height: 450px;
  overflow: auto;
}

.notification-active {
  display: block;
  z-index: 100;
}

.notification-closed {
  display: none;
}

.notification-content ul {
  margin: 0;
  padding: 0;
}

.notification-content ul li {
  cursor: pointer;
  list-style-type: none;
  padding: 5px 20px;
  font-size: 18px;
  text-align: left;
  border-bottom: 1px solid #dfdcdc;
}

.notification-content ul li:last-child {
  text-align: center;
  font-weight: 600;
  border-bottom: none;
}

.textDecoration {
  text-decoration: none !important;
}

.notification2 {
  display: none;
}

.notification-counter2 {
  display: none;
}

.notification-user-icon {
  margin: 5px;
  padding: 5px;
  background: #03b0cb;
  border-radius: 40px;
}

.notification-icons {
  display: flex;
  margin: 2px;
}

.notification-list {
  display: flex;
  align-items: center;
}

.notification-user-list {
  flex-basis: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notification-user-list .pending-request-list {
  display: contents;
  text-transform: capitalize;
}

.btn {
  width: 55px;
  height: 29px;
  text-align: center;
  margin: 2px;
}

.btn-edit {
  color: #FFF;
  background: #44CC44;
  padding: 5px 2px;
  box-shadow: 0 4px 0 0 #2EA62E;
}

.btn-remove {
  color: #FFF;
  background: tomato;
  padding: 5px 2px;
  box-shadow: 0 4px 0 0 #CB4949;
}

@media only screen and (max-width: 992px) {

  .left-side {
    padding: 15px 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-side>h3 {
    font-size: 14px;
  }

  .right-side>p {
    width: 90%;
  }

  .left-side>div:nth-of-type(2) {
    flex-direction: column;
  }

  .left-side>div:nth-of-type(1) h3,
  .left-side>div:nth-of-type(2)>div h3 {
    width: 80%;
    font-size: 11px;
    text-align: center;
  }

  .left-side img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .main-modal-header {
    width: 85%;
  }

  .notification2-closed {
    display: none;
  }

  .notification-content {
    position: absolute;
    margin: auto;
    z-index: 10;
  }

  .notification-content ul li {
    font-size: 16px;
  }

  .notification2 {
    display: block;
    position: absolute;
    top: 15px;
    right: 85px;
    cursor: pointer;
  }

  .notification-counter2 {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    top: -35px;
    left: 15px;
    font-size: 14px;
    text-align: center;
    background-color: #03b0cb;
    border-radius: 50%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .notification-content {
    width: 85vw;
    right: 30px;
    top: 50px;
  }
}
@media only screen and (max-width: 576px) {
 .headerContainer{
  display: none;
 }
 .header-container{
  display: flex !important;
 }
}

@media only screen and (max-width : 540px) {

  .modal-container {
    overflow: scroll;
  }

  .main-modal-header {
    transform: translate(-50%, -30%);
    top: 35%;
  }

  .left-side {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-box>p {
    margin: 20px 0 40px;
  }

  .right-side {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #c4d2d4a8;
    height: 100%;
  }

  .notification-user-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .notification-content {
    width: 90vw;
    right: 10px;
  }

  .notification-content ul li {
    font-size: 14px;
  }
}

@media only screen and (max-width : 420px) {
  .left-side {
    display: none;
  }
}

/* Notification Buttons */

.request-btn {
  padding-inline: 16px;
  padding-block: 5px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  border: none;
}

.confirm {
  background-color: #0095f6;
  color: #fff;
  margin-right: 5px
}

.reject {
  background-color: #efefef;
  color: #000;
}

.loginPage-main {
  width: 100%;
    height: 103%;
    overflow-x: hidden;
    background-color: #F6F6F6;
    position: fixed;
    top: -14px;
    z-index: 10;
}