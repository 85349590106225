.privacy-policy-main-mobile {
  padding: 16px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .privacy-policy-main-mobile > div:first-child {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px;
  }

  .privacy-policy-main-mobile div h1 {
    font-family: Open Sans;
    font-size: 19px;
    font-weight: 700;
    line-height: 29px;
  }
  .privacy-policy-main-mobile div h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }
  .privacy-policy-main-mobile div h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .privacy-policy-main-mobile div p ,.privacy-policy-main-mobile .privacy-policy-points li{
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .privacy-policy-main-mobile .privacy-policy-points li {
    padding-left: 20px;
    list-style-type: disc;
    list-style-position:outside;
}
}
