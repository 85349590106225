.view-profile {
    background-color: #f1f2f6;
}

.user-welcome {
    padding: 10px 0;
    background-color: #03b0cb;
    color: #fff;
}

.view-section {
    padding: 50px 0;
}

.view-section>div:nth-of-type(1) {
    padding: 2%;
    display: flex;
    align-items: center;
    box-shadow: 0 0 8px 3px #ced6e0;
}

.view-image {
    height: 270px;
    margin-right: 10px;
    flex: 1;
}

.view-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.view-details {
    height: 270px;
    padding: 2%;
    border-right: 1px solid #ced6e0;
    background-color: #fff;
    flex: 3;
}

.view-details h3 {
    font-size: 22px;
    text-transform: capitalize;
}

.view-details h3 span {
    margin-left: 20px;
    color: red;
    font-size: 18px;
    cursor: pointer;
}

.view-details>div {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
}

.view-details>div span {
    margin: 3px 0;
    flex-basis: 50%;
    text-transform: capitalize;
}

.view-details>p:nth-of-type(1) {
    padding-bottom: 12px;
    border-bottom: 2px solid #ced6e0;
}

.view-details>p:nth-of-type(1) span {
    margin-right: 20%;
}

.view-details>p {
    text-transform: capitalize;
}

.invitation-active {
    height: 270px;
    padding: 30px 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    flex: .5;
}

.invitation-closed {
    display: none;
}

.invitation-active button {
    padding: 10px;
    outline: 0;
    border: 0;
    border: 2px solid transparent;
    outline: 2px solid transparent;
    outline-offset: 4px;
    background-color: transparent;
    border-radius: 50%;
    transition: .4s ease-in;
}

.invitation-active button:nth-of-type(1) {
    border: 2px solid transparent;
    outline: 2px solid #9acd32;
    margin: 15px 0;
    background-color: #9acd32;
}

.invitation-active button:nth-of-type(2) {
    border: 2px solid transparent;
    outline: 2px solid #ffbe76;
    background-color: #ffbe76;
}

.invitation-active button:hover:nth-of-type(1) {
    outline: 2px solid transparent;
}

.invitation-active button:hover:nth-of-type(2) {
    outline: 2px solid transparent;
}

.detailed-profile {
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.profile-verified {
    margin-right: 10px;
    background-color: #fff;
    flex-basis: 23%;
}

.all-details {
    width: 100%;
    flex-basis: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.personal-det {
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.personal-det > div {
    display: flex;
    flex-direction: row;
}

.personal-det > div > div {
    flex-basis: 50%;
}

.all-details .left-text {
    font-weight: bold;
}

.user-family-det {
    display: flex;
    gap: 10px;
}
.user-family-det > div {
    width: 50%;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.education-career-det {
    display: flex;
    gap: 10px;
}
.education-career-det > div {
    width: 50%;
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aboutus-det {
    background-color: #fff;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.verified-logo {
    text-align: center;
    text-transform: capitalize;
}

.verified-logo h4 {
    padding: 15px 0;
    background-color: #f8f8ff;
    font-size: 18px;
}

.verified-logo h4>span {
    margin-left: 10px;
    color: #9acd32;
}

.verified-logo p {
    font-size: 15px;
}

.personal-detailed .tab {
    padding: 10px 0;
    border-bottom: 2px solid #ced6e0;
}

.personal-detailed .tab span {
    margin-left: 40px;
    color: #ff6b6b;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-decoration: none;
}

.personal-detailed ul {
    margin: 40px 0;
}

.personal-detailed li {
    list-style: none;
}

.personal-detailed li>div h5 {
    margin: 0;
    text-transform: capitalize;
}

.detailed-icon {
    padding: 10px 8px;
    border: 1px solid #ced6e0;
    margin-right: 20px;
    color: #ced6e0;
    border-radius: 50%;
}

.personal-detailed li>div>div {
    padding: 10px 10px 10px 0;
    margin-left: 60px;
    position: relative;
    color: rgb(149, 149, 157);
}

.personal-detailed li>div>div::before {
    content: "";
    position: absolute;
    top: 11px;
    left: -42px;
    width: 3px;
    height: 100%;
    background-color: #ced6e0;
}

.personal-detailed li>div>div span {
    font-size: 13px;
    text-transform: capitalize;
}

.personal-detailed li>div>div p {
    font-size: 15px;
    text-align: justify;
}

.contact-detailed,
.lifestyle-detailed {
    width: 250px;
    padding: 15px;
    border: 1px solid rgb(223, 224, 227);
    position: relative;
    border-radius: 3px;
}

.lifestyle-detailed {
    text-align: center;
}

.lifestyle-detailed span {
    font-size: 40px !important;
}

.contact-detailed>div {
    display: flex;
    align-items: flex-start;
}

.contact-detailed>div>div {
    margin-left: 10px;
}

.secure-contact {
    padding: 5px 10px;
    position: absolute;
    top: 55px;
    right: -19px;
    background-color: #ced6e0;
    color: #ff6b6b;
    font-size: 20px !important;
    border-radius: 50%;
}

.background-detailed h6,
.education-detailed h6 {
    margin-bottom: 10px;
    text-transform: capitalize;
}

.background-detailed h6>span,
.education-detailed h6>span {
    margin-right: 10px;
}

.preferences-detailed>div:nth-of-type(1) {
    padding-right: 40px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.preferences-detailed figure {
    width: 100px;
    height: 100px;
    border: 2px solid #ffbe76;
    border-radius: 50%;
}

.preferences-detailed img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.preferences-detailed>div p {
    padding: 5px 2%;
    background-color: #f1f2f6;
    border-radius: 50px;
}

.preferences-detailed .compare>div {
    padding: 10px 30px 10px 0;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #ced6e0;
}

.preferences-detailed .compare>div>span:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.minus-icon {
    padding: 5px 7px 7px;
    background-color: #ced6e0;
    border-radius: 50%;
}

.plus-icon {
    padding: 5px 6px 6px 8px;
    background-color: #9acd32;
    border-radius: 50%;
}

.top-button {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 100px;
    right: 100px;
    background-color: #5f9ea0;
    text-align: center;
    cursor: pointer;
}

.top-button span {
    font-size: 25px;
}


@media only screen and (max-width : 1024px) {
    .view-section>div:nth-of-type(1) {
        position: relative;
        flex-direction: column;
        color: #0dcaf0;
    }

    .view-details>div {
        margin: 0;
    }

    .view-details>p>span {
        color: #008000;
    }

    .view-image {
        width: 100%;
        height: 450px;
        margin-right: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .view-details {
        width: 100%;
        padding: 0 2%;
        border-right: 0;
        margin: 80px 0 0;
        background-color: transparent;
        backdrop-filter: blur(10px);
    }

    .invitation-active {
        width: 100%;
        padding: 0 5%;
        justify-content: space-between;
        flex-direction: unset;
        background-color: transparent;
        backdrop-filter: blur(25px);
    }

    .invitation-active span {
        display: none;
    }

    .detailed-profile {
        flex-direction: column;
    }

    .profile-verified {
        width: 100%;
        margin: 15px 0 15px 0;
    }

    .top-button {
        bottom: 85px;
        right: 30px;
    }
}

@media only screen and (max-width : 768px) {
    .preferences-detailed>div:nth-of-type(1) {
        padding-right: 0;
        flex-direction: column;
    }

    .preferences-detailed>div p {
        padding: 2% 8%;
        margin: 15px 0 25px;
    }

    .preferences-detailed figure {
        width: 150px;
        height: 150px;
    }

    .top-button {
        bottom: 60px;
    }

    .contact-detailed,
    .lifestyle-detailed {
        width: 90%;
    }
}

@media only screen and (max-width : 480px) {
    .view-section {
        padding: 30px 0;
    }

    .view-profile .user-welcome .wrapper > h3 {
        font-size: 15px;
    }
    
    .view-profile .page-heading .wrapper > h3 {
        font-size: 20px;
    }

    .view-details {
        margin: 10px 0 0;
    }

    .view-details>div {
        flex-direction: column;
    }

    .view-image {
        height: 400px;
    }

    .preferences-detailed figure {
        width: 80px;
        height: 80px;
    }

    .personal-detailed li>div h5 {
        font-size: 16px;
    }

    .preference-text {
        text-align: center !important;
    }

    .view-details>div span,
    .background-detailed h6,
    .education-detailed h6,
    .personal-detailed li>div>div p,
    .personal-detailed li>div>div span {
        font-size: 12px;
    }

    .view-details>p {
        font-size: 12px;
    }

    .invitation-active > button {
        font-size: 12px;
    }
}

@media only screen and (max-width : 350px) {
    .view-image {
        height: 360px;
    }
    
    .detailed-profile {
        margin: 10px 0;
    }

    .personal-detailed li>div>div {
        margin-left: 30px;
    }

    .personal-detailed li>div h5 {
        font-size: 15px;
    }

    .detailed-icon {
        margin-right: 8px;
    }

    .personal-detailed li>div>div::before {
        left: -16px;
    }

    .preferences-detailed .compare>div {
        margin-right: 0;
    }

    .preferences-detailed .compare>div>span:nth-of-type(2) {
        align-items: flex-start;
    }

    .top-button {
        bottom: 30px;
    }
}

.Details1 {
    display: flex;
    flex-direction: row
    /* width: auto;
    height: auto; */;
 }
 .Details2 {
     display: flex;
     width: auto;
     height: auto;
     /* margin-top: 5%; */
  }
 .Details3 {
     display: flex;
     width: auto;
     height: auto;
     /* margin-top: 5%; */
  }
 .DetailsInfo {
    margin: 5px;
    flex-basis: 50%;
     height: auto;
     width: auto;
     background-color: #fff;
     padding: 20px;
     margin-top: 20px 10px  ;
 }