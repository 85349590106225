.page__heading {
  padding: 10px 0;
  color: #fff;
}

.page__heading {
  background-color: rgba(0, 0, 0, .1);
}

.page__heading h4 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
}

.contact__locations {
  padding: 30px 0;
}

.contact__locations .address__map {
  padding: 2%;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .5);
  border-radius: 10px;
}

.contact__locations .address {
  margin-right: 10%;
  flex-basis: 40%;
}

.contact__locations .address h5 {
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(120, 120, 120, .2);
}

.contact__locations .map {
  height: 35vh;
  flex-basis: 50%;
}

.contact__locations .map iframe {
  width: 100%;
  height: 100%;
}

.contact__locations .address p {
  font-size: 18px;
  text-transform: capitalize;
}

.contact__locations .address p span {
  font-weight: 700;
}

.contact__locations .address p:nth-of-type(2) {
  margin: 25px 0;
}

.contact__feedback {
  padding: 2%;
  margin: 40px 3%;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .5);
  border-radius: 10px;
}

.contact__feedback h5 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact__feedback form>div:not(:last-child) {
  margin-bottom: 30px;
}

.contact__feedback .form__group {
  display: flex;
}

.contact__feedback .form__group>div:nth-of-type(1) {
  margin-right: 10%;
  flex-basis: 45%;
}

.contact__feedback .form__group>div:nth-of-type(2) {
  flex-basis: 45%;
}

.contact__feedback .form__group>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact__feedback label {
  flex-basis: 33%;
  font-weight: 600;
  text-transform: uppercase;
}

.contact__feedback label span {
  color: #f00;
}

.contact__feedback input {
  flex-basis: 62%;
}

.contact__feedback textarea {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
}

.contact__feedback input,
.contact__feedback textarea {
  padding: 5px 10px;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #000;
  background-color: rgba(120, 120, 120, .2);
  border-radius: 5px;
}

.contact__feedback form button {
  padding: 10px 25px;
  border: 0;
  outline: 0;
  background-color: #03b0cb;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-radius: 10px;
  transition: .5s ease-in;
}

.contact__feedback form button:hover {
  background-color: #0000b5;
}

@media only screen and (max-width : 996px) {
  .contact__feedback .form__group>div {
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }
}

@media only screen and (max-width : 768px) {
  .contact__locations .address {
    width: 100%;
    padding: 5%;
    margin-right: 0;
  }

  .contact__locations .address__map {
    flex-direction: column;
  }

  .contact__locations .map {
    width: 100%;
    flex-basis: unset;
  }

  .contact__feedback .form__group {
    flex-direction: column;
  }

  .contact__feedback .form__group>div:nth-of-type(1) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact__feedback {
    padding: 5%;
    margin: 40px 0;
  }

  .contact__feedback form button {
    padding: 5px 12px;
    font-size: 14px;
  }
}

@media only screen and (max-width:480px) {
  .page__heading h4 {
    font-size: 20px;
  }

  .contact__feedback h5,
  .contact__locations .address h5 {
    font-size: 18px;
  }

  .contact__locations .map {
    height: 25vh;
  }

  .contact__locations .address p,
  .contact__feedback label {
    font-size: 14px;
  }
}