.filter-range-slider .value-label-input-maxheight, .filter-range-slider .value-label-input-minheight {
 /* visibility: hidden; */
 display: none;
}

.filter-range-slider .value-label-maxheight {
  width: 70%;
  display: block;
  font-weight: 600;
  margin-right: 10px;
  text-align: start;
  text-transform: capitalize;
  font-size: 16px;
  text-wrap: balance;
}

.filter-range-slider .value-label-minheight {
  width: 70%;
  display: block;
     /* margin-top: 45%; */
    /* width: 100%; */
    font-weight: 600;
    /* margin-right: 10px; */
    text-align: start;
    text-transform: capitalize;
    /* position: absolute; */
    /* bottom: 40px; */
    font-size: 16px;
    text-wrap: balance;
}


.filter-range-slider .range-input-container {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 86%;
  position: relative;
}

.filter-range-slider {
  width: 95%;
  height: 94%;
  /* margin-top: 36%; */
  display: flex;
  justify-content: space-evenly;
}

.filter-range-slider .slider-container-outer {
  margin-top: 22px;
  height: 86%;
  flex-basis: 17%;
  margin-bottom: 30px;
}

.filter-range-slider .slider-container {
  width: 10px;
  height: 90%;
}

.filter-range-slider .rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #f11f4580 !important;
}

.filter-range-slider .rc-slider-rail {
  width: 4px;
  height: 88%;
  margin-top: 11px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 324px solid #dddddd;
  border-radius: 0 !important;
  right: 0px;
  background-color: transparent !important;
}

.filter-range-slider .rc-slider-vertical .rc-slider-handle {
  position: absolute;
  z-index: 1;
  margin-top: 0;
  margin-left: -2.5px;
  touch-action: pan-y;
  left: 4px;
}

.filter-range-slider .rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: transparent !important;
  border-radius: 6px;
}

.filter-range-slider .range-values {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  /* flex-basis: 45%; */
}


.filter-range-slider .range-values-minheight {
  position: absolute;
  bottom: 18px;
}
/* .max-range-value {
  position: absolute;
  top: 0;
}

.min-range-value {
  position: absolute;
  bottom: 20px;
} */

.filter-range-slider .rc-slider-handle {
  border: solid 2px #f11f45 !important;
}

.filter-range-slider .range-values input {
  padding: 5px !important;
  border: 1px solid #1f8cf1;
  border-radius: 10px;
  flex-basis: 76%;
  font-size: 18px;
  text-align: right;
}

.filter-range-slider .value-label-unit {
  flex-basis: 20%;
}


.filter-range-slider .rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px #f11f4580 !important;
}

.rc-slider-handle:active {
  border-color: #f11f4580;
  box-shadow: 0 0 5px #f11f4580;
  cursor: grabbing;
}

.filter-range-slider .value-label {
  width: 100%;
  font-weight: 600;
  margin-right: 10px;
  text-align: start;
  text-transform: capitalize;
}

.filter-range-slider .value-label-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: capitalize;
}

.filter-range-slider .value-text {
  font-weight: bold;
}

.filter-range-slider .error-field {
  height: 0px;
  font-size: 12px;
  position: absolute;
  bottom: -3px;
  text-wrap: balance;
  text-align: left;
  left: 7px;
}

.input-error {
  background-color: #f11f4580 !important;
  border:1px solid red !important;
}

/* .filter-range-slider .min-error {
  bottom: -18px;
}
.filter-range-slider .max-error {
  top: 18px;
} */
@media (max-width: 376px) {
  .filter-range-slider { 
    width: 90%;
    height: 90%;
    font-size: 10px;
    margin-top: 0;
  }
  .filter-range-slider .range-input-container {
    height: 85%; 
}

  .filter-range-slider .slider-container-outer {
    margin-top: 18px;
  }

  .filter-range-slider .range-values input {
    margin-right: 7px;
    padding: 5px !important;
    border: 1px solid #1f8cf1;
    border-radius: 10px;
    font-size: 12px;
  }

  .filter-range-slider .slider-container {
    width: 10px;
    height: 33vh;
  }

  .filter-range-slider .rc-slider-vertical {
    height: 98% !important;
  }

  .filter-range-slider .rc-slider-handle {
    width: 10px !important;
    height: 10px !important;
  }

  .filter-range-slider .rc-slider-rail {
    margin-top: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 200px solid #dddddd;
    right: 3px;
  }

  .filter-range-slider .error-field {
    font-size: 10px;
  }
  .filter-range-slider .value-label-maxheight {
    width: 70%;
    display: block;
    font-size: 12px;
  }
  
  .filter-range-slider .value-label-minheight {
    width: 70%;
    display: block;
    bottom: 0px;
    font-size: 12px;
  }

}

/* @media (max-width: 390px) {
  .filter-range-slider .value-label-maxheight {
    font-size: 12px;
  }
  
  .filter-range-slider .value-label-minheight {
    bottom: 46px;
    font-size: 12px
}
} */