/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
.main-swiper-container {
  height: 70vh;
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.profile-slider {
  position: relative;
  width: 90%;
  height: 67vh;
  overflow: hidden;
  background-color: grey;
  color: #f6f6f6;
  border-radius: 10px;
}

.header {
  width: 100%;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0 20px;
}

.searchBarArrowLeft {
  width: 35px;
  height: 35px;
  margin-right: 15px;
  border: none;
  background: none;
  background-color: none;
  cursor: pointer;
  font-size: 25px;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.search-bar {
  margin-right: 10px;
  width: 65%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border-bottom: 1px solid #d5cdcd;
  -webkit-transition: width 0.1s ease;
  -o-transition: width 0.1s ease;
  transition: width 0.1s ease;
  background-color: #ffffff;
}

.search-btn {
  width: 20%;
  background-size: contain;
  border: none;
  border-radius: 20px 0 0 20px;
  background-color: white;
}

.search-btn img {
  height: 24px;
  width: 24px;
  border-radius: 20px 0 0 20px;
}

.input-bar {
  width: 80%;
  outline: none;
  border: none;
  height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  border-radius: 0 20px 20px 0;
  padding-right: 15px;
}

.searchBarCross {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-size: 20px;
  color: #000000;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.expanded {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.filter-btn,
.bell-btn {
  width: 15%;
  border: none;
  background-color: #f6f6f6;
}

.bell-counter {
  width: 25px;
  height: 20px;
  color: white;
  position: absolute;
  top: -6px;
  right: -8px;
  border-radius: 12px;
  background-color: #f11f45;
  font-size: 12px;
  line-height: 20px;
}

.filter-btn img {
  width: 35px;
  height: 35px;
  background-size: 90%;
}

.bell-btn {
  position: relative;
}

.bell-btn img {
  width: 30px;
  height: 30px;
  background-size: 90%;
}

.profile-info {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: -o-linear-gradient(bottom, #2929299c 0%, #ffffff00 57%);
  background: -webkit-gradient(linear, left bottom, left top, from(#2929299c), color-stop(57%, #ffffff00));
  background: linear-gradient(to top, #2929299c 0%, #ffffff00 57%);
}

.message-btn {
  border-radius: 50%;
  background: transparent;
  border: none;
}

.message-btn img {
  width: 55px;
  height: 55px;
}

.detail-btn {
  background: transparent;
  border: none;
}

.detail-btn img {
  border-radius: 50%;
  width: 68px;
  height: 68px;
}

.share-btn {
  border-radius: 50%;
  background: transparent;
  border: none;
}

.share-btn img {
  width: 55px;
  height: 55px;
}

.user-name {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 10px 0 5px 13px;
  text-transform: capitalize;
  font-size: 19px;
}

.verfied-icon {
  margin-left: 5px;
}

.verfied-icon img {
  width: 19px;
  padding-bottom: 5px;
  float: none;
}

.heart-icon {
  position: absolute;
  right: 15px;
  bottom: 20px;
}

.heart-icon img {
  width: 32px;
  height: 32px;
}

.user-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 1px;
  margin-left: 13px;
  font-family: "Open Sans";
  font-size: 17px;
}

.location-icon {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.location-icon img {
  width: 10px;
  height: 14px;
}

.btn-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  width: 100%;
}

.btn-section > button {
  display: flex;
  align-items: center;
}

.image-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.image-slider .swiper-wrapper {
  width: 100%;
}

.image-slider .swiper-slide {
  /* width: 100%;
  height: 100%; */
  height: 67vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-slider img {
  /* width: 100vw;
  height: 100vh;
  width: 100vw;
  height: 100vmax;
  max-width: 100%;
  max-height: 100%; */
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  width: 90%;
  height: 250px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-info {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.modal-text {
  height: 60%;
  width: 76%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-text img {
  width: 40px;
  height: 40px;
}

.modal-info p {
  padding-top: 5px;
  font-family: "Open Sans";
  font-size: 20px;
  text-wrap: "balance";
  text-align: center;
}

.cancel-request,
.send-request {
  width: 100px;
  height: 45px;
  border: none;
  position: absolute;
  letter-spacing: 1px;
}

.cancel-request {
  left: 30px;
  bottom: 20px;
  background-color: #fff;
  color: #a4a4a4;
}

.cancel-request::after {
  content: "";
  position: absolute;
  width: 55px;
  height: 1px;
  background-color: #a4a4a4;
  bottom: 11px;
  left: 22px;
}

.send-request {
  background-color: #1f8cf1;
  border-radius: 8px;
  right: 30px;
  bottom: 20px;
  color: #ffffff;
}

.close-modal-btn {
  border: none;
  background-color: transparent;
}

.close-modal-btn img {
  height: 30px;
}

.modal-header {
  width: 100%;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  background-color: #f6f6f6;
}

.swiper-pagination {
  top: 5px !important;
  width: 100%;
  height: 10px;
}

.swiper-pagination-bullet {
  width: 10% !important;
  height: 5.5px !important;
  border-radius: 10px !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

.background-slider-1,
.background-slider-2 {
  height: 1.5vh;
  margin: 0 auto;
  border-radius: 0 0 10px 10px;
}

.background-slider-1 {
  bottom: -2.5%;
  left: 8%;
  width: 85vw;
  background: #faa9b7;
}

.background-slider-2 {
  bottom: -5%;
  left: 11%;
  z-index: -1;
  width: 78vw;
  background: #fdd9df;
}

.lower-section {
  width: 100% !important;
  height: 10vh;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #dddddd;
  background-color: #F6F6F6;
  z-index: 99;
}

.lower-section button {
  all: unset;
}

.lower-section img {
  width: 60px;
  height: 60px;
}

.profile-page-text {
  text-align: center;
}

.user-profile {
  /* position: relative; */
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}

/* CSS when reset filter button is shown*/
.no-results-mobile {
  height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.no-results-mobile .d-flex {
  margin-top: 50px;
}

.no-results-mobile .d-flex button {
  border: 1px solid #1f8cf1;
  border-radius: 10px;
  padding: 10px;
  background-color: transparent;
}

/* Searchbar */
.searchByNameContainer {
  padding: 10px 25px 0 25px;
}

.searchByNameProfileWrapper {
  border-bottom: 1px solid #ddd;
  padding: 10px 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.searchByNameProfileDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.searchByNameProfileImg {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -o-object-position: 50% 9%;
  object-position: 50% 9%;
}

.searchByNameProfileName {
  font-size: 17px;
  font-weight: 600;
  color: #353535;
  cursor: pointer;
}

.searchByNameProfileArrow {
  cursor: pointer;
  font-size: 30px;
  color: #7d6262;
}

/* @media only screen and (max-width: 376px) {

  .profile-slider,
  .image-slider .swiper-slide {
    height: 67vh;
  }
} */