body{
  overflow: auto !important;
}
.profileViewMobContainer{
  background-color: white;
  color: #1E2742;
  font-family: 'Open Sans';
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  overflow: scroll;
  height: 100vh;
}
.backToHome{
  padding-top: 49px;
  padding-bottom: 30px;

}
.textBold{
  font-size: 16px;
}
.pdf{
  border: 1px solid #A4A4A4;
}
.myProfileMobdetail{
  font-family: Open Sans;
font-size: 14px;
font-weight: 400;
color: #1E2742;


}
.contactButton{
  height: 52px;
  width: 182px;
  border: 1.5px solid #DDDDDD
}
.messageButton{
  height: 52px;
  width: 111px;
  border: 1.5px solid #DDDDDD
}
.textSmall{
  font-size: 14px;
}

.ulStyle{
  list-style-position: outside;
  padding-left: 1rem;
}

.list{
  list-style-type: disc;
}
.shortlistedsection{
  font-family: Open Sans;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0px;
text-align: left;
color: #1E2742;
}
.accordion-item {
  border: none;
}
.accordionItem{
  width: 100%;
}
.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ffffff;
  box-shadow: none;
}
.hr{
  background-color: #b7b7b7;
  padding: 0.9px;
}
.featuredImages{
  display: flex;
  gap: 10px;
  height: 120px;
  overflow: scroll;
}
.profileImage{
  height: 109px;
  width: 109px;
    border-radius: 50%;
  object-fit: cover;
}
.dark{
 color: #1E2742;
}
.fullName{
font-size: 16px;
font-weight: 700;
line-height: 24px;
}
.featuredImage{
  width: 65px;
  height: 118px;
  object-fit: cover;
}

.profile-view-slider .slider {
  width: 90%;
  height: 55%;
}

.profile-view-slider {
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  top: 0;
}

.profile-view-cancel {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  z-index: 101;
}

.view-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactModal {
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100%;
  top: 0;
  background-color: rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactModal > div .modal-content {
  width: 100%;
}
.contactModal > div {
  background-color: #fff;
}

.backToHome {
  align-items: center;
}

.backToHome img{
  height:12px
}