.career_form>.wrapper {
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .3);
  width: 100% !important;
}

.career_form>.wrapper .form_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.career_form>.wrapper .form_grid {
  margin-bottom: 30px;
}
.career_form>.wrapper .form_grid:last-child {
  margin-bottom: 0;
}

.career_form>.wrapper .form_grid:last-child>span {
  flex-basis: 100%;
  justify-content: space-between;
}

.career_form>.wrapper .form_grid>span {
  flex-basis: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.career_form>.wrapper .form_grid>span:nth-of-type(1) {
  margin-right: 4%;
}

.career_inputField>select,
.career_inputField>input {
  padding: 2%;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #000;
  flex-basis: 48%;
  border-radius: 5px;
}
.radio_btns {
  width: 48%;
}
.radio_btns  span  label {
  padding-left: 16px;
}

@media only screen and (max-width : 1024px) {
  .radio_btns {
    width: 50%;
  }
}

@media only screen and (max-width : 768px) {
  .career_form>.wrapper .form_grid:not(:last-child) {
    margin-bottom: 0;
  }

  .career_form>.wrapper .form_grid>span:nth-of-type(1) {
    margin-right: 0;
  }

  .career_form>.wrapper .form_grid:not(:last-child) {
    flex-direction: column;
    align-items: flex-start;
  }

  .career_inputField {
    margin-bottom: 20px;
  }

  .career_inputField>select {
    padding: 2% 5%;
  }

  .career_inputField>label {
    flex-basis: 40%;
  }

  .career_inputField>input[type="text"],
  .career_inputField>select {
    flex-basis: 50%;
  }

  .radio_btns {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    /* align-items: center; */
    font-size: 12px;
    flex-basis: 50%;
  }

  .radio_btns  span>label {
    font-size: 16px;
    padding-left: 10px;
  }
}

@media only screen and (max-width : 480px) {
  .career_form>.wrapper .form_grid>span {
    flex-direction: column;
    align-items: unset;
  }
  .radio_btns {
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: row;
  }
  .radio_btns  span>label {
    font-size: 16px;
    padding-left: 5px;
  }
}

#career_form_error {
  text-align: center;
}

#career_form_error>span {
  color: #f00;
  animation: blink 1s linear 3;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}