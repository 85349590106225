.message-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;
}

.message-modal .message-content {
  background-color: #fff;
  border-radius: 4%;
  width: 90%;
  height: 38%;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
  bottom: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.message-modal .message-content .message-head {
  flex-basis: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f6f6f6;
}
.message-modal .message-content .message-head span{
  font-size: 20px;
}
.message-modal .message-content .message-text {
  flex-basis: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-modal .message-content .message-text > textarea{
  width: 90%;
  height: 80%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin: 15px auto;
  padding: 10px;
  outline: none;
}
.message-modal .message-content .message-btns {
  flex-basis: 10%;
  width: 80%;
  margin: 15px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.message-modal .message-content .message-btns > button {
  all: unset;
  padding: 5px 20px;
  text-decoration: underline;
  letter-spacing: 1px;
  font-size: 20px;
  color: #A4A4A4;
}
.message-modal .message-content .message-btns > button:last-child {
  background-color: #1f8cf1;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
}