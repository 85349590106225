footer {
  padding: 30px 0;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, .1);
  text-transform: capitalize;
}

footer ul {
  padding: 0;
  margin: 0;
}

footer li a {
  color: #000;
  font-family: math;
}

footer .footer-container {
  margin-bottom: 20px;
  display: flex;
}

footer .footer-container ul:not(:last-child) {
  margin-right: 5%;
}

footer .footer-container li h5 {
  font-weight: 600;
}

footer .follows-us {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

footer .follows-us p {
  margin: 0;
  font-weight: 600;
}

footer .follows-us li img {
  width: 25px;
}

footer .follows-us li a:not(:last-child) {
  margin-right: 22px;
}

@media only screen and (max-width: 996px) {
  footer .follows-us p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  footer .follows-us p {
    margin-bottom: 20px;
    line-break: auto;
  }

  footer .follows-us {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  footer .footer-container {
    flex-direction: column;
  }

  footer .footer-container ul:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}