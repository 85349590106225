.registration-main .title1 h1 img {
  float: right;
  margin-right: 3px;
  margin-top: 5px;
}

.registration-main .title2 h1 {
  margin-right: 3px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.registration-main h1 img {
  width: 24px;
  height: 24px;
  margin-right: 3px;
  margin-top: 5px;
}

.registration-main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

.registration-main .registration {
  flex-grow: 1;
  padding: 20px;
}

.registration-main .registration h1 {
  text-align: center;
  margin: 30px 0;
}

.registration-main .details label {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.registration-main .details input {
  width: 100%;
  font-size: 14px;
  padding-inline: 10px;
  padding-block: 8px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.registration-main .details img {
  width: 100%;
  margin-top: 15px;
}


.registration-main .gender {
  display: flex;
  align-items: center;
  width: 70%;
  height: 20px;
}

.registration-main .gender>input {
  width: 40px !important;
}

.registration-main .gender>label {
  margin-bottom: 0px !important;
}

.registration-main .details select {
  width: 100%;
  padding-block: 8px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: black;
}

.registration-main .cancel button {
  background: none;
  border: none;
}

.registration-main .save_button button {
  width: 100%;
  border: none;
  padding-block: 13px;
  font-size: 17px;
  font-weight: 700;
  background-color: #1F8CF1;
  color: white;
  margin-bottom: 30px;
}

.registration-main .registration2 {
  flex-grow: 1;
  padding: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .registration-main .registration h1 {
    font-size: 24px;
    margin: 20px 0;
  }
}