/* PROFILE FEED GRID */

.single-profile {
  width: 500px;
  height: 650px;
  padding: 10px;
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, .1); */
}

.single-profile img {
  width: 480px;
  height: 630px;
  object-fit: cover;
  border-radius: 15px;
}

.single-profile .shadow {
  width: 480px;
  height: 630px;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .7) 92%);
  position: absolute;
  top: 10px;
  border-radius: 15px;
}

.single-profile .single-profile-data {
  width: 460px;
  padding: 2%;
  z-index: 1000;
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #fff;
  text-shadow: 1px 1px 5px #000;
}

.single-profile .single-profile-data h3,
.single-profile .single-profile-data h5 {
  text-transform: capitalize;
}

.single-profile .single-profile-data h3 {
  font-weight: 700;
}

.single-profile .single-profile-data h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.single-profile .single-profile-data h5>span:first-child {
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, .3);
  letter-spacing: 1.3px;
  border-radius: 20px;
  text-shadow: 1px 1px 5px #000;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
}

.single-profile .single-profile-data h5>span:nth-of-type(2) {
  padding: 1%;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, .1);
  border-radius: 50%;
  cursor: pointer;
}

.single-profile .icon-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-profile .icon-button>span {
  padding: 8px 12px;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 50%;
  transition: .3s ease-in;
}

.single-profile .icon-button>span:nth-of-type(1) {
  border: 2px solid#e1c05a;
}

.single-profile .icon-button>span:nth-of-type(1)>svg {
  color: #e1c05a;
}

.single-profile .icon-button>span:nth-of-type(2) {
  border: 2px solid#dd4c61;
}

.single-profile .icon-button>span:nth-of-type(2)>svg {
  color: #dd4c61;
}

.single-profile .icon-button>span:nth-of-type(3) {
  border: 2px solid#44c8ff;
}

.single-profile .icon-button>span:nth-of-type(3)>svg {
  color: #44c8ff;
}

.single-profile .icon-button>span:nth-of-type(4) {
  border: 2px solid#52f6b9;
}

.single-profile .icon-button>span:nth-of-type(4)>svg {
  color: #52f6b9;
}

.single-profile .icon-button>span:nth-of-type(5) {
  border: 2px solid#b155dd;
}

.single-profile .icon-button>span:nth-of-type(5)>svg {
  color: #b155dd;
}

.profilelist_container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.profile_feed_container.vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container_profile_feed {
  border-radius: 8px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1);
  background-color: #ffffff;
}

.profile_feed_container.horizontal {
  width: 50%;
  height: 85vh;
  margin: 0 auto;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.profile_feed_container::-webkit-scrollbar {
  display: none;
}

.single_profile_feed {
  height: 100%;
  padding: 2%;
  scroll-snap-align: start;
  position: relative;
}

.profile_feed_name {
  padding-top: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.single-profile-data h4 {
  padding-bottom: 5px;
}

/* .profile_card_row {
  padding: 5px 0 10px 0;
} */
.profile_card_row > span {
  font-size: 22px;
  font-weight: 500;
}

.profile_card_row:first-child {
  padding-bottom: 10px;
}

.horizontal_line {
  border-bottom: 1px solid #ddd;
  margin: 10px 10px 15px;
}

.image-profile {
  height: 40vh;
  position: relative;
  padding: 0 0 0 0;
  text-align: center;
  transition: .5s ease-in;
}

.image-profile>img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  object-fit: contain;
}

.slide-image {
  width: 39vh;
  height: 100%;
  margin: 0 auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* transform: translateY(-100%); */
}

.slide-image.active {
  display: block;
}

.slide-image.remove {
  display: none;
}

.profile_feed_details {
  height: 25vh;
  padding: 0 80px;
  display: flex;
  overflow-y: scroll;
  text-align: center;
  display: none !important;
}

.flex-item-left,
.flex-item-right {
  text-align: left;
  width: 50%;
  padding: 10px;
}

.profile_feed_details::-webkit-scrollbar {
  display: none;
}

/* buttons */
.profile_list_showmoreBtn {
  width: auto;
  color: rgb(114, 21, 200);
  font-weight: 500;
  cursor: pointer;
}

.profile_list_interact_btn {
  padding: 0 10px;
}

.profile_list_interact_btn>span {
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  background-color: #132656;
  color: white;
  text-align: center;
  cursor: pointer;
}

/* NOTIFICATION GRID */
.profile_list_notification>div {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
}

.profile_list_notification>div>span {
  padding-left: 10px;
}

.profile-list-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid black;
  padding: 10px;
  margin-bottom: 5px;
}

.checkbox {
  display: flex;
  flex-direction: row;
}

.checkbox>label {
  padding-left: 5px;
}

.search-options {
  border: 2px solid #dee2e6;
  padding: 5px;
  margin-bottom: 5px;
}

.filters-main {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filters {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
  margin-left: 0;
}

.filters-line {
  display: flex;
  flex-direction: column;
}

.filters-line>button {
  background-color: #94c9ff;
  border: 0;
  padding: 3px;
  margin-bottom: 5px;
  border-radius: 10px;
  font-size: 12px;
  width: 100%;
  color: #fff;
}

.filters-line>button:active {
  background-color: #03b0cb;
}

.filters-options>div {
  padding-bottom: 15px;
  display: none;
}

.filters-options div>label {
  padding-left: 2px;
  margin-right: 15px;
}

.profileIcons {
  display: flex;
  justify-content: space-around;
  filter: drop-shadow(5px 5px 10px rgba(20, 20, 20, 0.3));
}

.profileicon {
  transform: translate(15%, -105%);
  height: 40px;
  width: 40px;
  margin: 0 20px;
  text-decoration: none;
  transition: transform 0.5s;
  text-shadow: 3px 6px rgba(255, 165, 0, 0.75);
  cursor: pointer
}

.contact-btn {
  color: #0288d1;
}

.pending-btn {
  color: #cecece;
}

.show-more-data {
  width: 480px;
  height: 630px;
  z-index: 1000;
  overflow: auto;
  position: absolute;
  top: -457px;
  left: -10px;
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
}

.show-more-data::-webkit-scrollbar {
  display: none;
}

.show-more-data .user-image {
  width: 90%;
  height: 60%;
  margin: 10px auto;
  position: relative;
}

.show-more-data .user-image a>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 8px 4px rgba(255, 255, 255, 1);
}

.show-more-details {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.show-more-details>ul {
  padding: 0;
  margin: 0;
  flex: 1;
}

.show-more-details>ul>li {
  font-size: 16px;
  font-weight: 900;
}

.cancel-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
  font-size: 20px;
  color: #f00;
}

.view-more-details {
  margin: 10px 0;
  text-align: center;
}

.view-more-details>a {
  padding: 2px 12px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.22);
  background-color: #F00;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  border-radius: 5px;
  transition: .5s ease-in;
}

.flex-item-left>p:nth-of-type(1),
.show-more-details>ul>li:nth-of-type(1) {
  text-transform: capitalize;
}

@media only screen and (min-width : 768px) {
  .view-more-details>a:hover {
    border: 1px solid transparent;
    background-color: #40e0d0;
    color: #000;
  }

  .single-profile .icon-button>span:nth-of-type(1):hover,
  .single-profile .icon-button>span:nth-of-type(2):hover,
  .single-profile .icon-button>span:nth-of-type(3):hover,
  .single-profile .icon-button>span:nth-of-type(4):hover,
  .single-profile .icon-button>span:nth-of-type(5):hover {
    border: 2px solid transparent;
  }
}

.single-profile .slick-next{
  right: 30px !important;
  z-index: 100;
  background: transparent !important;
}
.single-profile .slick-prev{
  left: 25px !important;
  z-index: 100;
  background: transparent !important;
}
.single-profile .slick-prev:before, .single-profile .slick-next:before {
  font-size: 30px;
}

@media only screen and (max-width : 991px) {
  .single-profile {
    width: 435px;
    height: 600px;
  }

  .single-profile img,
  .single-profile .shadow {
    width: 415px;
    height: 580px;
  }

  .single-profile .single-profile-data {
    width: 400px;
  }

  .single-profile .Popup-Container {
    height: 57vh;
    top: -425px;
    left: 40px;
  }

  .profile_feed_details {
    height: 31vh;
  }

  .profile_feed_details {
    padding: 0 20px;
  }

  .show-more-data {
    width: 450px;
    height: 650px;
    top: -490px;
    left: -25px;
  }

  .show-more-data .user-image {
    height: 54%;
  }
}

@media only screen and (max-width : 540px) {
  .single-profile {
    margin: 0;
  }

  .single-profile img,
  .single-profile .shadow,
  .single-profile {
    width: 100%;
    height: 100%;
  }

  .single-profile .single-profile-data {
    width: 85%;
  }

  .single-profile .shadow {
    left: 0;
  }

  .single-profile .single-profile-data h3 {
    font-size: 20px;
  }

  .single-profile .single-profile-data h5 {
    font-size: 12px;
  }

  .single-profile .single-profile-data h5>span:first-child {
    padding: 3px 5px;
  }

  .single-profile .icon-button>span {
    padding: 3px 5px;
  }

  .single-profile .Popup-Container {
    height: 40vh;
    top: -125px;
    left: 2%;
  }

  .profile-list-heading>h4 {
    font-size: 10px;
  }

  .filters {
    width: 100%;
  }

  .filters-line>button {
    font-size: 6px;
  }

  .Popup_profile_feed_img {
    height: 30vh;
    padding: 0 0 0 0;
    text-align: center;
  }

  .image-profile {
    height: 32vh;
  }

  .horizonatl-slider {
    width: 90%;
  }

  .show-more-data {
    width: 115%;
    height: 500px;
    overflow: scroll;
    top: -310%;
    left: -4%;
  }

  .show-more-data .user-image {
    width: 390px;
    height: 350px;
  }
}

/* tablet view */
@media (max-width: 800px) {
  .profile_feed_details {
    flex-direction: column;
  }

  .flex-item-left {
    text-align: left;
    width: 100%;
    padding: 0 15px 0px;
  }

  .profile_feed_details {
    height: 35vh;
  }

  .flex-item-right {
    text-align: left;
    width: 100%;
    padding: 0 15px 0px;
  }
}

@media only screen and (max-width: 850px) {
  .filters-line>button {
    font-size: 10px;
  }

  .filters {
    width: 90%;
  }

  .profile-list-heading>h4 {
    font-size: 17px;
  }

  .sort {
    font-size: 15px;
  }

  .filters-options>div {
    padding-bottom: 12px;
    display: none;
    font-size: 12px;
  }

  .profile_feed_details {
    height: 35vh;
    font-size: 15px;
  }
}

@media (max-width: 820px) and (max-height: 1180px) {
  .profile_feed_img {
    width: 100%;
  }

  .profile_feed_details {
    height: 35vh;
    font-size: 14px;
  }
}

/* mobile */
@media screen and (max-width: 663px) {
  .profile_feed_img {
    width: 100%;
    margin: 0 auto;
  }

  .profile_feed_details {
    height: 40vh;
  }

  .filters-line>button {
    font-size: 8px;
  }

  .profile-list-heading>h4 {
    font-size: 15px;
  }

  .sort {
    font-size: 13px;
  }
}

@media screen and (max-width: 575.25px) {
  .profile_feed_img {
    width: 100%;
    margin: 0 auto;
  }

  .profile_feed_details {
    height: 26vh;
    font-size: 15px;
    flex-direction: column;
  }

  .profile_feed_img {
    margin: 0 auto;
  }

  .profile_feed_details {
    height: 30vh;
    font-size: 12px;
  }

  .filters-line>button {
    font-size: 8px;
  }

  .profile-list-heading>h4 {
    font-size: 12px;
  }

  .sort {
    font-size: 10px;
  }
}

@media only screen and (max-width : 480px) {
  .show-more-data {
    height: 365px;
    top: -240%;
    left: -5%;
  }

  .show-more-data .user-image {
    width: 100%;
    height: 220px;
  }

  .show-more-details>ul>li {
    font-size: 12px;
  }
}

@media only screen and (max-width : 390px) {
  .show-more-data {
    top: -191%;
    left: -6%;
  }
}

@media only screen and (max-width : 320px) {
  .single-profile .Popup-Container {
    height: 40vh;
    top: -70px;
    left: 2%;
  }

  .show-more-data {
    width: 115%;
    height: 330px;
    top: -170%;
    left: -9%;
  }
}