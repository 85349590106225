.profile__photo .user__welcome {
  padding: 10px 0;
  background-color: #03b0cb;
  color: #fff;
}

.profile__photo .user__welcome h3 {
  font-size: 20px;
}

.profile__photo .page__heading {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  text-transform: capitalize;
}

.profile__photo .page__heading h4 {
  font-size: 30px;
}

.profile__photo .profile__upload {
  padding: 2%;
  display: flex;
}

.profile__photo .profile__content>.wrapper {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  width: 100% !important;
}

.profile__content .profile__browse__photo input {
  display: none;
}

.profile__photo .profile__content .profile__image {
  width: 320px;
  height: 320px;
  margin-right: 6%;
  box-shadow: 5px 5px 5px #00000085;
}

.profile__photo .profile__content .profile__image img {
  width: 100%;
  height: 100%;
}

.profile__photo .profile__content .profile__browse__photo {
  flex-basis: 70%;
}

.profile__photo .profile__content .profile__browse__photo>div {
  padding: 8% 0;
  border: 3px solid #00c6ff61;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #00c6ff30;
}

.profile__photo .profile__browse__photo>div label {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
}

.profile__photo .profile__browse__photo>div label .cameraIcon {
  font-size: 40px;
}

.profile__photo .profile__browse__photo h5 {
  margin: 30px 0;
  text-transform: capitalize;
  text-align: center;
}

.profile__photo .profile__browse__photo p {
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
}

.profile__photo .profile__browse__photo p span {
  font-weight: 600;
}

.profile__content .profile__button {
  padding: 20px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile__content .profile__button button {
  padding: 5px 20px;
  border: 0;
  outline: 0;
  background-color: #03b0cb;
  color: #fff;
  text-transform: uppercase;
  transition: 0.5s ease-in;
  letter-spacing: 1.5px;
  border-radius: 5px;
}

.profile__content .profile__button button:hover {
  background-color: #0000b5;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
}

.add-profile-images>label {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed;
  border-radius: 15px;
  width: fit-content;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}

.add-profile-images {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.add-profile-images>span {
  color: red;
}

.add-profile-images label>input {
  display: none;
}

.uploaded-profile-images-row>.added-images {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
}

.add-profile-images div>h1 {
  padding: 10%;
  text-align: center;
}

.upload-mult-img-btn {
  font-weight: 600;
  border: 1px solid black;
  padding: 1%;
  border-radius: 5px;
  cursor: pointer;
}

.upload-mult-img-btn:hover {
  border: 1px solid #03b0cb;
  color: #03b0cb;
}

.uploaded-profile-images-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;
  flex-wrap: wrap;
  align-items: center;
}

.uploaded-profile-images-row .added-images>.uploaded-profile-images {
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
  box-shadow: 5px 10px 10px 10px #888888;
}

@media only screen and (max-width: 1024px) {
  .profile__photo .profile__content .profile__image {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .profile__photo .profile__content .profile__image {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 580px) {

  .profile__photo .user__welcome h3,
  .profile__photo .page__heading h4 {
    font-size: 18px;
  }

  .profile__photo .profile__upload {
    flex-direction: column;
  }

  .profile__photo .profile__upload {
    align-items: center;
  }

  .profile__photo .profile__content .profile__image {
    width: 70%;
    height: 70%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profile__content .profile__button button {
    letter-spacing: unset;
    font-size: 13px;
  }
}

@media only screen and (max-width: 380px) {
  .profile__photo .user__welcome h3 {
    font-size: 14px;
  }

  .profile__photo .profile__browse__photo>div label {
    font-size: 16px;
  }

  .profile__photo .profile__browse__photo input[type="file"] {
    width: 100%;
  }

  .profile__photo .profile__browse__photo p {
    font-size: 14px;
  }

  .profile__content .profile__button {
    padding: 10px;
  }
}