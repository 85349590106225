.editMyProfile_wrapper h3 span:last-child{
    margin-left: 10%;
    font-size: 16px;
}

.editMyProfile_main_container_personal_info section,
.editMyProfile_main_container_personal_details section,
.editMyProfile_main_container_family_details section,
.editMyProfile_main_container_education_details section,
.editMyProfile_main_container_carrer_details section {
  width: 85%;
  margin: auto;
}

.editMyProfile_main_container_personal_info h4:first-child span:nth-child(2),
.editMyProfile_main_container_personal_details h4:first-child span:nth-child(2),
.editMyProfile_main_container_family_details h4:first-child span:nth-child(2),
.editMyProfile_main_container_education_details
  h4:first-child
  span:nth-child(2),
.editMyProfile_main_container_carrer_details h4:first-child span:nth-child(2) {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 700;
}

.editMyProfile_main_container .editMyProfile_main_container_personal_info,
.editMyProfile_main_container_personal_details,
.editMyProfile_main_container_family_details,
.editMyProfile_main_container_education_details {
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

@media screen and (max-width: 564px) {
  .editMyProfile_wrapper {
    max-width: 1360px;
    width: 95%;
    margin: 0 auto;
    height: 90dvh;
    overflow-y: auto;
  }

  .editMyProfile_main_container .editMyProfile_wrapper {
    padding: 10px;
    position: relative;
  }

  .editMyProfile_main_container .editMyProfile_wrapper > h3,
  .editMyProfile_main_container_personal_info > h4,
  .editMyProfile_main_container_family_details > h4,
  .editMyProfile_main_container_carrer_details > h4,
  .editMyProfile_main_container_personal_details > h4,
  .editMyProfile_main_container_education_details > h4 {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }

  .editMyProfile_main_container
    .editMyProfile_wrapper
    > h3
    span:nth-of-type(1) {
    flex-basis: 30%;
  }

  .editMyProfile_main_container
    .editMyProfile_wrapper
    > h3
    span:nth-of-type(2) {
    flex-basis: 70%;
  }

  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(1),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(1) {
    flex-basis: 8%;
    color: #f00;
  }

  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(2),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(2) {
    flex-basis: 84%;
    text-transform: capitalize;
    font-weight: 600;
  }

  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(3),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(3) {
    flex-basis: 8%;
  }

  .userInfo_details > .userinfo_data,
  .education_details-data_info .education-info,
  .familyDetail-data .family-info,
  .personal_region-data .personal_data-info,
  .CarrerDetail-data-info .carrer-info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .userInfo_details > div > span:nth-of-type(1),
  .education-info > span:nth-of-type(1),
  .personal_data-info > span:nth-of-type(1),
  .family-info > span:nth-of-type(1),
  .carrer-info > span:nth-of-type(1) {
    flex-basis: 35%;
    text-transform: capitalize;
  }

  .userInfo_details > div > span:nth-of-type(2),
  .education-info > span:nth-of-type(2),
  .personal_data-info > span:nth-of-type(2),
  .family-info > span:nth-of-type(2),
  .carrer-info > span:nth-of-type(2) {
    flex-basis: 5%;
  }

  .userInfo_details > div > span:nth-of-type(3),
  .education-info > span:nth-of-type(3),
  .personal_data-info > span:nth-of-type(3),
  .family-info > span:nth-of-type(3),
  .carrer-info > span:nth-of-type(3) {
    flex-basis: 60%;
  }

  .edit_personal_details,
  .edits_family_details,
  .edit_personal_detail_data,
  .edit_education_details,
  .edit_carrer_details {
    width: 100%;
    height: 100vh;
    padding: 3% 5%;
    z-index: 1000;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  .edit_personal_details > h4,
  .edits_family_details > h4,
  .edit_education_details > h4,
  .edit_carrer_details > h4,
  .edit_personal_detail_data > h4 {
    margin: 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 18px;
  }

  .edit_personal_details > h4 > span:nth-of-type(1),
  .edit_education_details > h4 > span:nth-of-type(1),
  .edit_carrer_details > h4 > span:nth-of-type(1),
  .edit_personal_detail_data > h4 > span:nth-of-type(1),
  .edits_family_details > h4 > span:nth-of-type(1) {
    flex-basis: 20%;
    color: #8a8e9c;
  }

  .edit_personal_details > h4 > span:nth-of-type(2),
  .edit_education_details > h4 > span:nth-of-type(2),
  .edit_carrer_details > h4 > span:nth-of-type(2),
  .edit_personal_detail_data > h4 > span:nth-of-type(2),
  .edits_family_details > h4 > span:nth-of-type(2) {
    flex-basis: 80%;
    font-weight: 400;
  }

  .edit_personal_details > h6,
  .edits_family_details > h6,
  .edit_education_details > h6,
  .edit_carrer_details > h6,
  .edit_personal_detail_data > h6 {
    margin-bottom: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 20px;
  }

  .edit_personal_details > h6 > span:nth-of-type(1),
  .edit_education_details > h6 > span:nth-of-type(1),
  .edit_carrer_details > h6 > span:nth-of-type(1),
  .edit_personal_detail_data > h6 > span:nth-of-type(1),
  .edits_family_details > h6 > span:nth-of-type(1) {
    flex-basis: 20%;
    color: #f11f45;
  }

  .edit_personal_details > h6 > span:nth-of-type(2),
  .edit_education_details > h6 > span:nth-of-type(2),
  .edit_carrer_details > h6 > span:nth-of-type(2),
  .edit_personal_detail_data > h6 > span:nth-of-type(2),
  .edits_family_details > h6 > span:nth-of-type(2) {
    flex-basis: 80%;
    font-weight: 600;
  }

  .edit_personal_details .input-block,
  .edits_family_details .input-block,
  .edit_education_details .input-block,
  .edit_carrer_details .input-block,
  .edit_personal_detail_data .input-block {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
  }

  .edit_personal_details .input-block > label,
  .edits_family_details .input-block > label,
  .edit_education_details .input-block > label,
  .edit_carrer_details .input-block > label,
  .edit_personal_detail_data .input-block > label {
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .edit_personal_details .input-block > input,
  .edit_personal_details .input-block > select,
  .edits_family_details .input-block > input,
  .edits_family_details .input-block > select,
  .edit_education_details .input-block > input,
  .edit_education_details .input-block > select,
  .edit_carrer_details .input-block > input,
  .edit_carrer_details .input-block > select,
  .edit_personal_detail_data .input-block > input,
  .edit_personal_detail_data .input-block > select {
    padding: 5px 0;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #000;
  }

  .input-block > h6 {
    text-transform: capitalize;
    font-weight: 600;
  }

  .input-block-btn {
    margin-top: 20px;
    border-radius: 5px;
    background-color: #1e90ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .input-block-btn > button {
    width: 100%;
    padding: 5px 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    letter-spacing: 2.5px;
  }

  .married-unmarried > div > label {
    text-transform: capitalize;
    font-weight: 500;
  }

  .married-unmarried > div > span {
    font-weight: 600;
  }

  .unmarried,
  .married {
    margin-bottom: 10px;
  }

  .btn-range {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-range > input {
    flex-basis: 80%;
  }

  .btn-range > button {
    flex-basis: 8%;
    border: 0;
    outline: 0;
    background-color: transparent;
    border: 1px solid #1e90ff;
    border-radius: 5px;
  }

  .btn-range > button:nth-of-type(1) {
    margin-right: 2%;
  }

  .btn-range > button:nth-of-type(2) {
    margin-left: 2%;
  }

  .editMyProfile_main_container .lds-roller {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 80px;
    top: 230px;
  }
}

@media only screen and (max-width: 320px) {
  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(2),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(2),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(2) {
    flex-basis: 70%;
    font-size: 16px;
  }

  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(1),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(1),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(1) {
    flex-basis: 15%;
  }

  .editMyProfile_main_container_personal_info > h4 span:nth-of-type(3),
  .editMyProfile_main_container_carrer_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_personal_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_family_details > h4 span:nth-of-type(3),
  .editMyProfile_main_container_education_details > h4 span:nth-of-type(3) {
    flex-basis: 15%;
  }

  .edit_personal_details > h6 > span:nth-of-type(2),
  .edit_education_details > h6 > span:nth-of-type(2),
  .edit_carrer_details > h6 > span:nth-of-type(2),
  .edit_personal_detail_data > h6 > span:nth-of-type(2),
  .edits_family_details > h6 > span:nth-of-type(2) {
    font-size: 15px;
  }

  .edit_personal_details > h4,
  .edits_family_details > h4,
  .edit_education_details > h4,
  .edit_carrer_details > h4,
  .edit_personal_detail_data > h4 {
    font-size: 14px;
  }

  .input-block-btn > button {
    padding: 3px 0;
    font-size: 16px;
  }

  .edit_personal_details .input-block > label,
  .edits_family_details .input-block > label,
  .edit_education_details .input-block > label,
  .edit_carrer_details .input-block > label,
  .edit_personal_detail_data .input-block > label {
    font-size: 14px;
  }
}

.Toastify {
  position: relative;
  z-index: 999999 !important;
}