.multiple-slider {
    height: 100vh;
}
.multiple-slider .cancel-slider {
    position: absolute;
  top: 7%;
  left: 20px;
  font-size: 20px;
  z-index: 101;
  display: flex;
  align-items: center;
}
.multiple-slider > div:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    position: relative;
}
.multiple-slider > div:nth-of-type(1) > span {
    font-size: 20px;
    position: absolute;
    top: 47%;
}
.multiple-slider > div:nth-of-type(2) {
    height: 60vh;
    border-bottom: 1px solid #cecece;
    margin: 20px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multiple-slider > div:nth-of-type(2) img {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    object-fit: cover;
    background-color: rgb(0, 0, 0, 0.2);
}
.multiple-slider > div:nth-of-type(3) {
    height: 20vh;
    margin: 20px;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: scroll;
    /* background: #645455; */
}
.multiple-slider > div:nth-of-type(3) img {
    border-radius: 5px;
    height: 70%;
    background-color: rgb(0, 0, 0, 0.2);
}
.multiple-slider > div:nth-of-type(3) .selectedImage {
    border: 3px solid #3798f1;
}
