.wrapper {
    max-width: 1360px;
    width: 87.13%;
    margin: 0 auto;
}
.testimonial {
    padding: 2% 0;
    background-color: #F6F2E7;
}
.testimonial h3 {
    margin-bottom: 4%;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
}
.testimonial .testimonial_images img {
    width: 350px;
}
.testimonial .wrapper span {
    color: #B56665;
}
.testimonial_images button {
    padding: 5px 15px;
    border: 0;
    outline: 0;
    background-color: #B56665;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    transition: .5s ease-in;
    cursor: pointer;
}
.testimonial_images button:hover {
    box-shadow: 0 0 5px 2px rgba(0,0,0,.3);
}
.testimionial1:not(:last-child) {
    margin-bottom: 2%;
}
.testimionial1:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.testimionial1:nth-child(even) img {
    margin-left: 20px;
}
.testimionial1:nth-child(even) h5 {
    text-align: center;
    text-transform: capitalize;
}
.testimionial1:nth-child(odd) {
    display: flex;
    align-items: center;
    margin-right: 10;
}
.testimionial1:nth-child(odd) img {
    margin-right: 20px;
}
.testimonial_text p {
    font-size: 20px;
    text-align: justify;
    line-height: 1.5;
}
.img_text h5 {
    margin-top: 2%;
}

@media only screen and (max-width : 995px) {
    .testimonial_text p {
        font-size: 15px;
    }
}

@media only screen and (max-width : 768px) {
    .testimionial1:nth-child(odd), 
    .testimionial1:nth-child(even) {
        flex-direction: column;
        align-items: flex-start;
    }
    .testimonial .testimonial_images img {
        width: 100%;
    }
    .testimionial1:nth-child(even) img {
        margin: 0;
    }
    .bottom_text {
        margin-top: 5%;
    }
    .testimionial1:not(:last-child) {
        margin-bottom: 8%;
    }
}

@media only screen and (max-width : 480px) {
    .testimonial h3 {
        font-size: 25px;
    }
    .testimonial_text p {
        line-height: unset;
        font-size: 14px;
    }
    .testimonial_images button {
        padding: 3px 8px;
        font-size: 14px;
    }
    .img_text h5 {
        font-size: 20px;
    }
}