.container.short-list-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #1e2742;
  margin-top: 30px;
  position: fixed;
  top: -31px;
  z-index: 9999;
  background: #f6f6f6;
  left: 0;
  height: 90%;
}

.container.short-list-container .div-content {
  width: 95%;
  overflow: scroll;
  height: 600px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 10px;
}
.container.short-list-container .div-content::-webkit-scrollbar {
  display: none;
}
.container.short-list-container .shortListHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container.short-list-container .shortListHeader img {
  width: 24px;
  height: 24px;
}
.container.short-list-container .shortlist-heading {
  padding: 20px;
  height: 85px;
}
.container.short-list-container .shortlist-selected {
  padding: 0 10px;
  display: flex;
}
.container.short-list-container .pageTitle {
  text-align: center;
  font-size: 16px;
}
.container.short-list-container .shortlist-selected .selected {
  flex: 5 1;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.container.short-list-container .shortlist-selected p{
  font-size: 16px;
}

.container.short-list-container .profile-wraper {
  box-shadow: -4px 7px 16px -7px rgba(179, 177, 179, 1);
  height: 128px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.container.short-list-container .profile-detail {
  width: 128px;
  height: 128px;
  position: relative;
}
.container.short-list-container .profile-detail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 8px 0px 0px 8px;
}
.container.short-list-container .checkImage {
  position: absolute;
  top: 10px;
  left: 10px;
}
.container.short-list-container .profileContent {
  font-size: 14px;
  display: flex;
  flex: 60%;
  flex-direction: column;
  padding: 5px;
  height: 100%;
}
.container.short-list-container .classDisplay {
  display: flex;
  height: 21px;
  gap: 10px;
}
.container.short-list-container .classDisplay img {
  width: 20px;
  height: 20px;
}
.container.short-list-container .name {
  border-bottom: 1px solid #faa9b7;
  height: 24px;
  text-align: center;
  padding-bottom: 10px;
}
.container.short-list-container .name h3 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
}
.container.short-list-container .location {
  margin-bottom: 10px;
  padding: 5px 0 0 15px;
  height: 21px;
}
.container.short-list-container .ShortlistFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  width: 360px;
  padding: 0px 40px;
  position: sticky;
  border-top: 1px solid rgba(112, 108, 108, 0.1);
}
.container.short-list-container .recent,
.container.short-list-container .selected p {
  font-weight: 600;
  font-size: 16px;
}
.container.short-list-container .footerImg {
  width: 28px;
  height: 28px;
}

.container.short-list-container .shortlist-selected-Delete {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
}
.container.short-list-container .select-cancel {
  border: none;
  width: 66px;
  height: 21px;
  background-color: transparent;
  font-size: 14px;
  color: #1f8cf1;
  font-weight: 700;
}
.container.short-list-container .delete-shortlist-btn {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: red;
  font-weight: 700;
}
.container.short-list-container .ShortlistFooter-Delete {
  display: flex;
  justify-content: center;
  height: 67px;
  width: 100%;
  background: #f6f6f6;
  position: fixed;
  bottom: 0;
}
.container.short-list-container .dialog {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-radius: 8px;
}

.container.short-list-container .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 41px;
  border-radius: 8px 8px 0 0;
  background-color: #f6f6f6;
}
.container.short-list-container .modal-content-shortlist {
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container.short-list-container .modal-header {
  border-bottom: 1px solid transparent;
}
.container.short-list-container .modal-header h5 {
  margin-top: 10px;
  width: 90%;
  text-align: center;
}
.container.short-list-container .modal-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.container.short-list-container .modal-btn button {
  font-weight: 600;
  color: #a4a4a4;
}
.container.short-list-container .modal-btn > :first-child {
  border-bottom: 1px solid #a4a4a4;
}
.container.short-list-container .modal-message {
  padding: 2px 90px;

  text-align: center;
}
.container.short-list-container .modal-btn {
  padding: 0 0 10px 0;
}
.container.short-list-container .modal-deleteBtn {
  background-color: #f11f45 !important;
  width: 100px !important;
  height: 36px !important;
  color: white !important;
  border-radius: 8px !important;
  text-align: center !important;
  font-size: 16px;
}
.container.short-list-container .crossImg {
  width: 10%;
}
.container.short-list-container .modal-overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #1e274280;
}
.container.short-list-container .no-profile-found {
  margin: auto;
}
.container.short-list-container .selected-option {
  font-weight: bold;
}
.container.short-list-container .sorted {
  background: none;
  border: none;
  outline: none;
  height: 25px;
  border-bottom: 1px solid #1e2742;
  font-size: 16px;
}

.shortlistContent .profile-link {
  color: #1e2742;
}
.short-list-container .loader-container {
  margin: auto;
}

/* Loader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1e274280;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
