.body .user-welcome .wrapper {
  display: flex;
  align-items: center;
}

.body .content .wrapper > p {
  margin-top: 2%;
  color: #555;
  font-size: 18px;
}

.body .content p {
  text-align: justify;
  font-size: 18px;
}

.body .content span {
  color: #f00;
  font-weight: 700;
  text-transform: capitalize;
}

.content .abuse_lists li {
  list-style-type: square;
}

.content form {
  margin: 5% 0;
}

.content form .form_group {
  display: flex;
}

.content form .form_group:not(:last-child) {
  margin-bottom: 2%;
}

.content form .form_group label {
  margin-bottom: 3%;
  color: #555;
  font-weight: 600;
  text-transform: uppercase;
}

.content form .form_group input,
.content form .form_group select,
.content form .form_group textarea {
  padding: 1% 0 1% 1.5%;
  outline: 0;
  border: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.content form .form_group:nth-of-type(1) div {
  flex-basis: 30%;
}

.content form .form_group:nth-of-type(1) div:not(:last-child) {
  margin-right: 5%;
}

.content form .form_group div {
  flex-basis: 45%;
}

.content form .form_group div:not(:last-child) {
  margin-right: 10%;
}

.content form div:last-child div:last-child {
  justify-content: flex-end;
}

.content form .form_group div {
  display: flex;
  flex-direction: column;
}

.content form .form_group:last-child div {
  margin-top: 2%;
  flex-direction: unset;
}

.content form .invalid {
  background: red;
  padding: 1%;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.5px;
  border-radius: 10px;
  margin-bottom: 2%;
}

@media only screen and (min-width: 768px) {
  .content form .form_group button:hover {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.6);
  }
}

@media only screen and (max-width: 995px) {
  .content form .form_group label {
    text-transform: capitalize;
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .body .user-welcome h2 {
    font-size: 13px;
  }

  .content form .form_group {
    flex-direction: column;
  }

  .content form .form_group:nth-of-type(1) div:not(:last-child),
  .content form .form_group div:not(:last-child) {
    margin: 0 0 3%;
  }

  .body .content .wrapper > p {
    line-height: unset;
  }

  .content h4 {
    margin: 10% 0 2%;
    font-size: 20px;
  }

  .content .abuse_lists p {
    font-size: 14px;
  }

  .content form .form_group:last-child div {
    margin-top: 2%;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .body .user-welcome .wrapper {
    justify-content: flex-start;
  }

  .body .content .wrapper > p,
  .content .abuse_lists p {
    font-size: 12px;
  }

  .content h4 {
    font-size: 16px;
  }

  .content form .form_group label {
    font-size: 14px;
  }

  .content form .light-button {
    letter-spacing: unset;
  }

  .content form .form_group button {
    font-size: 14px;
  }

  .content form .invalid {
    padding: 1% 3%;
    margin-bottom: 5%;
    border-radius: 0;
  }
}