.family-details {
    width: 100%;
}
.family-details form {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .3);
}

.family-details form input,
.family-details form select {
    border: 0 !important;
    border-bottom: 2px solid #000 !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.family-details form input:focus,
.family-details form select:focus {
    box-shadow: unset !important;
}

.family-details form input[type="text"] {
    text-align: left !important;
}

.family-wrapper {
    width: 100% !important;
}

.family-saveclosebtns {
    display: flex;
    justify-content: space-between;
    padding: 25px;
  }
  
  .family-saveclosebtns button {
    padding: 6px 10px;
    outline: 0;
    border: 0;
    background-color: #03b0cb;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: 0.5s ease-in;
    border-radius: 5px;
  }
  .family-saveclosebtns button:hover {
    background-color: #0000b5;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
  }

@media only screen and (max-width:996px) {
    .family-details {
        max-width: 100%;
    }
    .family-saveclosebtns {
        padding: 10px;
    }
    .family-saveclosebtns button {
        font-size: 12px;
    }
}