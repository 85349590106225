.notification-main {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  position: relative;
}

.notification-main .notification {
  flex-grow: 1;
  padding: 20px;
  padding-right: 0;
  background-color: #fff;
}

.notification-main .readMessage {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-main .readMessage .readMessageContent {
  background: #fff;
  width: 90%;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}

.notification-main .readMessage .readMessageContent > div:first-child {
  flex-basis: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.notification-main .readMessage .readMessageContent > div:last-child {
  flex-basis: 90%;
  margin: 15px;
  border: 1px solid #f6f6f6;
  padding: 10px;
  overflow: scroll;
}

.notification-main .notification > div:nth-child(1) {
  display: flex;
  margin: 20px 0;
  position: relative;
  justify-content: center;
  align-items: center;
}

.notification-main .notification > div:nth-child(1) > span {
  position: absolute;
  left: 0;
  top: -5px;
  font-size: 25px;
}

.notification-main .notification h1 {
  text-align: center;
  margin: 30px 0;
}

.notification-main .notification h6 {
  font-weight: 700;
}

.notification-main .btn-warning {
  color: white;
  background-color: #f6a01f;
}

.notification-main .new-notification,
.notification-main .earlier-notification {
  color: #1e2742;
}

.notification-main .new-section .btn,
.notification-main .earlier-section .btn {
  width: 72px;
  height: 36px;
}

.notification-main .cross-btn,
.notification-main img.cross-btn,
.notification-main .share-btn,
.notification-main p.msg-time,
.notification-main .message-icon,
.notification-main .additional-message {
  width: 24px;
  height: 24px;
}

.notification-main .cross-btn {
  width: 28px;
  height: 20px;
  border: none;
  background: none;
}

.notification-main .additional-message img {
  width: 26px;
  height: 26px;
  margin-right: 4px;
  position: relative;
  bottom: 6px;
}

.notification-main .additional-message .message-text {
  background-color: #e5e5e5;
  padding: 7px;
  border-radius: 4px;
}

.notification-main .time {
  position: relative;
  bottom: 20px;
  color: #a4a4a4;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .notification-main .notification h1 {
    font-size: 24px;
    margin: 20px 0;
  }

  .notification-main .new-notification,
  .notification-main .earlier-notification {
    height: 100px;
    flex-direction: inherit;
    align-items: flex-start;
    position: relative;
    top: 3px;
  }

  .notification-main .new-section .btn,
  .notification-main .earlier-section .btn {
    width: 72px;
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 12px;
  }
}

/* Additional Styles for the New Section */
.notification-main .new-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.notification-main .new-section .contactLabel,
.notification-main .new-section .messageLabel {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.notification-main .new-section .labelPhoto {
  flex-basis: 15%;
}

.notification-main .new-section .labelPhoto > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.notification-main .new-section .labelName {
  flex-basis: 60%;
  padding: 0 5px;
}

.notification-main .new-section .labelName div > span {
  font-size: 18px;
  font-weight: 600;
}

.notification-main .new-section .acceptBtn,
.notification-main .new-section .readBtn,
.notification-main .new-section .closeBrn {
  flex-basis: 10%;
}

.notification-main .new-section .messageContent {
  padding: 15px 10px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 0 20px 10px 10px;
  width: 80%;
  float: right;
}

@media (max-width: 325px) {
  .notification-main .new-section .labelName div > span {
    font-size: 14px;
  }
  .notification-main .new-section .labelName {
    font-size: 12px;
  }
  .notification-main .new-section > h3 {
    font-size: 16px;
  }
  .new-section .messageContent {
    font-size: 12px;
    padding: 10px 5px;
    width: 60%;
  }
}
