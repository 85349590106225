.biodata-wrapper {
  max-width: 1360px;
  width: 87.13%;
  margin: 0 auto;
}

.biodata-heading {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1% 0;
}

.biodata-heading .biodata-wrapper h2 {
  width: 90%;
  margin: auto;
  color: #000;
  font-size: 35px;
  font-weight: 700;
}

.biodata-content {
  width: 100%;
  padding: 0 0 20px;
  margin: 40px auto;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.biodata-content-pdf-grid {
  display: flex;
  flex-direction: row;
}
.bio-data-grid-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bio-data-grid-display > form {
  height: 90vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.bio-data-grid-display > form::-webkit-scrollbar {
  display: none;
}
.bio-data-grid {
  width: 20ch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.biodata-content .dummy-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.biodata-content .dummy-data > h3 {
  padding: 2% 0%;
}

.biodata-content .dummy-data > span {
  color: #ffffff;
  background-color: #03b0cb;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  height: auto;
}

.biodata-content form .showErrorMessage {
  color: #f00;
  text-align: center;
  font-size: 18px;
  animation: blink 1s linear 3;
}

.biodata-content form div > h3 {
  padding: 2% 10%;
  color: #03b0cb;
  font-size: 30px;
  margin: 0;
}

.biodata-content form label {
  font-size: 16px;
  padding: 1% 0;
  font-weight: 600;
  width: auto !important;
}

.biodata-content form > div > div {
  padding: 1% 5%;
}

.biodata-content form > div > div .added_inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 5px;
}

.biodata-content form > div > div .added_inputs input {
  flex-basis: 80%;
  font-size: 1rem;
}

.added_inputs span {
  border: 0;
  outline: 0;
  background-color: transparent;
  text-transform: capitalize;
  cursor: pointer;
}

.biodata-content form > div > div .added_inputs > div {
  flex-basis: 15%;
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.biodata-content form div > div .gender {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.biodata-content form div > div .gender > input {
  width: 100%;
  margin-right: 2%;
}
.biodata-content form div > div .gender > label {
  margin-right: 5%;
}
.biodata-content form .gender-checkbox {
  display: flex;
  justify-content: space-between;
}
.biodata-content form input,
.biodata-content form select {
  width: 60%;
  padding: 1%;
  outline: none;
  border: 1px solid #7d7272;
  border-radius: 5px;
  height: fit-content;
}
.biodata-content form .biodata-dob-input {
  height: auto !important;
}
.print_button {
  text-align: center;
}

.print_button button {
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background-color: #03b0cb;
  padding: 0.5% 2%;
  margin: 2% 0;
  font-size: 25px;
  text-transform: capitalize;
}
.bio-data-grid {
  align-items: center;
}
.bio-data-grid .pdf-previews {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1% 6%;
  grid-template-columns: auto auto auto auto;
  height: 100%;
}

.bio-data-grid-display .bio-data-grid .pdf-previews div {
  padding: 4px;
  height: fit-content;
}
.bio-data-grid-display .bio-data-grid .pdf-previews .download-image-biodata {
  width: 100px;
  height: 150px;
  border: 1px solid #03b0cb;
  border-radius: 5px;
}
.biodata-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.download-image-biodata:hover {
  transform: scale(1.5);
  transition: all 0.4s ease;
}

.added-fields {
  width: 60%;
}

.added-labels > label {
  align-self: flex-start;
}

.download-image-biodata {
  width: 80px;
  height: 120px;
  border: 1px solid #03b0cb;
  border-radius: 5px;
}
.biodata-details > div {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  font-size: 14px;
  width: 50%;
}
.biodata-details > div > label .biodata-details > div > input {
  height: fit-content;
}
.biodata-main-details > .about {
  width: 100%;
  padding: 1%;
}
.biodata-main-details > .about .input-field > label {
  flex-basis: 10%;
  font-size: 16px;
  padding: 1% 0;
  font-weight: 600;
  width: auto !important;
}
.biodata-main-details > .about .input-field > .text-box {
  flex-basis: 90%;
}

.added-inputs .checkbox {
  height: 25px;
}
.added-inputs {
  padding: 0;
  margin-top: 5px;
}
.added_inputs div > .add-btn {
  vertical-align: inherit !important;
  color: greenyellow;
  margin-left: 2px;
}
.added_inputs div > .remove-btn {
  vertical-align: inherit !important;
  color: red;
  margin-left: 2px;
}
.tickbox {
  height: 20px !important;
}
@media only screen and (max-width: 1024px) {
  .biodata-heading .biodata-wrapper h2 {
    width: 100%;
    font-size: 30px;
  }

  .biodata-content {
    width: 100%;
  }

  .biodata-content form div h3 {
    font-size: 25px;
  }
  .biodata-content form input,
  .biodata-content form select {
    width: 40%;
  }
  .biodata-content form > div > div {
    padding: 1% 3%;
  }
  .biodata-content form label {
    font-size: 15px;
  }

  .print_button button {
    font-size: 22px;
  }

  .pdf-previews div {
    padding: 2px;
  }

  .added-fields {
    font-size: 12px;
  }
}
@media only screen and (max-width: 920px) {
  .biodata-details {
    flex-direction: column;
    width: 100%;
  }
  .biodata-content form input,
  .biodata-content form select {
    width: 60%;
  }
  .biodata-details > div {
    width: 100%;
  }
  .about .input-field > .text-box {
    margin-left: 2%;
  }
}
@media only screen and (max-width: 700px) {
  .biodata-wrapper {
    width: 100%;
  }

  .biodata-content {
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
    box-shadow: none;
  }

  .biodata-heading .biodata-wrapper h2 {
    width: 90%;
    font-size: 25px;
  }

  .biodata-content form {
    width: 100%;
  }

  .biodata-content form div h3 {
    font-size: 18px;
  }

  .biodata-content form label {
    font-size: 12px;
  }

  .print_button button {
    font-size: 16px;
  }

  .biodata-content form input,
  .biodata-content form select {
    width: 60%;
  }

  .biodata-content form div h3 {
    padding: 2% 5%;
  }

  .biodata-content form div > div .gender {
    width: 60%;
    font-size: 12px;
  }

  .biodata-content form input,
  .biodata-content form select {
    font-size: 12px;
  }

  .pdf-previews {
    grid-template-columns: auto auto;
  }

  .pdf-previews > div {
    padding: 2px;
  }

  .added-fields {
    width: 60%;
    padding: 0 !important;
  }

  .biodata-content .dummy-data > span {
    font-size: 12px;
  }
  .bio-data-grid-display .bio-data-grid .pdf-previews .download-image-biodata {
    width: 60px;
    height: 90px;
    border: 1px solid #03b0cb;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 480px) {
  .biodata-content form div div {
    flex-direction: column;
    align-items: flex-start;
  }

  .biodata-content form input,
  .biodata-content form select {
    width: 100%;
  }

  .biodata-content form div > div .gender {
    display: flex;
    flex-direction: row;
    width: 70%;
  }

  .biodata-content form .showErrorMessage {
    font-size: 14px;
  }

  .pdf-previews {
    height: 100%;
  }

  .added-fields {
    width: 100%;
  }

  .added-inputs {
    flex-direction: row;
  }

  .biodata-content form > div > div .added_inputs {
    flex-direction: row;
  }

  .biodata-content form > div > div .added_inputs input {
    width: 70%;
    flex-basis: 80%;
  }

  .biodata-content form > div > div .added_inputs > div {
    flex-direction: row;
  }

  .biodata-content .dummy-data {
    flex-direction: row;
  }
  .biodata-main-details > .about {
    display: flex;
    flex-direction: column;
    height: 100pxs;
  }
}