
.terms-conditions-mobile {
  padding: 16px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .terms-conditions-mobile > div:first-child {
    margin-bottom: 15px;
  }

  .terms-conditions-mobile div h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 29px;
  }
  .terms-conditions-mobile div h5{
font-family: Inter;
font-size: 13px;
font-weight: 700;
line-height: 18px;
  }

  .terms-conditions-mobile div h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }

  .terms-conditions-mobile div h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .terms-conditions-mobile div p,
  .terms-conditions-mobile .privacy-policy-points li {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .terms-conditions-mobile .terms-conditions-points li {
    padding-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
  }
}
