.basicSideFilter {
  border-radius: 5px;
}

.basicCheckBoxDropDown {
  border-radius: 5px;
}

.btn_secondary_search,
.btn_danger_search {
  border: 0;
  outline: 0;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}

.btn_secondary_search {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn_danger_search {
  background-color: #312e2e99;
  color: #fff;
}

.basicDropDown {
  border-radius: 5px;
  width: 80%;
}

.search_div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8ed;
}

.search_result.vertical {
  width: 550px;
  max-height: 80vh !important;
  overflow-y: scroll;
  margin: 0 auto;
  scroll-snap-type: y mandatory;
}

.search_result.vertical::-webkit-scrollbar {
  display: none;
}

.icon_horizontal,
.icon_vertical {
  cursor: pointer;
}

.h-icon {
  color: #fff;
  background-color: #00a6ed;
  border: 1px solid;
  border-radius: 5px;
  padding: 0 5px;
  width: 35px;
}

.horizontal-slider .slick-prev {
  width: 35px !important;
  height: 35px !important;
  left: 35px !important;
  z-index: 10 !important;
  background: #03b0cb !important;
}

.horizontal-slider .slick-next {
  width: 35px !important;
  height: 35px !important;
  right: 35px !important;
  z-index: 10 !important;
  background: #03b0cb !important;
}

@media screen and (max-width: 991px) {
  .search_result.vertical {
    width: 480px;
  }
}

@media screen and (max-width: 768px) {
  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-container>div:nth-of-type(2) {
    width: 600px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .horizontal-slider .slick-prev {
    left: 20px !important;
  }

  .horizontal-slider .slick-next {
    right: 20px !important;
  }
}

.searchPageLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .search_result.vertical {
    width: 100%;
  }

  .horizontal-slider .slick-prev {
    left: 15px !important;
  }

  .horizontal-slider .slick-next {
    right: 15px !important;
  }
  
}

.main-search-container {
  max-width: 1600px;
  margin: auto;
  padding: 0 25px;
}

.search-title p {
  color: #282c3f;
  margin: 8px 20px;
  padding-left: 20px;
}

.search-title strong,
.search-filers strong {
  color: #000;
}

.search-filers {
  display: flex;
  flex-direction: row;
  margin: 5px 20px;
  color: #282c3f;
  padding-left: 20px;
}

.search-filers .search-filter-heading {
  display: flex;
  align-items: center;
  flex-basis: 21%;
}

.search-filers .search-filter-content {
  align-items: center;
  flex-basis: 65%;
}

.search-sort {
  overflow: visible;
  flex-basis: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  height: 36px;
  font-size: 14px;
}

.search-sort>div {
  border: 1px solid #d1d1d6;
  width: 100%;
}

.no-border {
  border-bottom: 0 solid transparent !important;
}

.search-filter-content .search-filter-btns>button {
  all: unset;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 14px;
  margin-right: 5px;
  color: #282c3f;
}

.search-filter-content .search-filter-btns>button:hover {
  background-color: #e8e8ed !important;
  border-radius: 20px !important;
}

.search-filter-content>div label {
  font-size: 14px;
}

.btn-clicked {
  background-color: #e8e8ed !important;
  border-radius: 20px !important;
}

.search-container2 {
  display: flex;
  flex-direction: row;
  margin: 5px 20px;
}

.search-left-container {
  border-top: 1px solid #edebef;
  border-right: 1px solid #e8e8ed;
  width: 20%;
  height: 750px;
  padding-bottom: 15px;
  overflow-y: scroll;
}

.search-left-container::-webkit-scrollbar {
  width: 6px;
}

.search-left-container::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 5px;
}

.search-left-container .basic-search-container .checkbox-search {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 25px;
  border-bottom: 1px solid #e9e9ed;
}

.search-left-container .basic-search-container .checkbox-search>span {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 0 18px;
  clear: both;
  color: #282c3f;
  display: block;
}

.search-left-container .basic-search-container .checkbox-search>div>ul {
  margin: 0;
  padding: 0;
}

.search-left-container .basic-search-container .checkbox-search>div>ul>li {
  list-style-type: none;
  font-size: 14px;
  margin-bottom: 7px;
}

.search-left-container .basic-search-container .checkbox-search>div>ul>li>label {
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
}

.search-left-container .basic-search-container .checkbox-search>div>ul>li>label>input {
  margin: 0 16px 0 0;
}

.basic-search-container .checkbox-search>h6 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.basic-search-container .search-select-container {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 25px;
  border-bottom: 1px solid #e9e9ed;
}

.dropdown-search {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 25px;
  border-bottom: 1px solid #e9e9ed;
}

.dropdown-search>div>div .label {
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
}

.search-right-container {
  width: 80%;
}

.basic-search-btn {
  all: unset;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.basicCheckBoxDropDown label,
.basicDropDown label {
  font-size: 14px;
}

.basicSiderFilter>div {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 25px;
  border-bottom: 1px solid #d1d1d6;
}

.basicSiderFilter>div>button {
  font-weight: 700;
}

.sort-operations {
  cursor: pointer;
  display: none;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d1d1d6;
  border-top: 1px solid transparent;
  z-index: 10;
  padding: 0;
}

.sort-operations li {
  cursor: pointer;
  padding: 10px 20px;
  transition: 0.4s ease-in;
}

.search-sort:hover .sort-operations {
  display: block;
}

.search-sort>div>p {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
  padding: 0 10px;
}

.bold-text {
  font-weight: 700;
  background-color: #e8e8ed;
}

.search-left-container>div>.container-popup {
  max-height: 310px;
  overflow-y: scroll;
}

.sort-operations li:hover {
  background-color: #e8e8ed;
}

.modal-btn {
  position: relative;
}

.modal-btn button {
  all: unset;
  cursor: pointer;
  color: red;
}

.search-modal-container {
  width: 650px;
  height: 525px;
  z-index: 1001;
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaec;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  top: 38%;
  left: 10%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.search-modal-container1 {
  flex-basis: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 18px 12px;
  border-bottom: 1px solid #eaeaec;
  font-size: 14px;
  color: #adaeaf;
}

.search-modal-container1 input {
  height: 30px;
  width: 170px;
  font-size: 15px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  text-transform: capitalize;
}

.search-modal-container1 ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 60%;
  justify-content: space-around;
}

.search-modal-container1 ul li {
  font-weight: 600;
  width: 15px;
  height: 15px;
  align-self: center;
  cursor: pointer;
}

.search-modal-container1 .close-search-btn {
  align-self: center;
  cursor: pointer;
  opacity: 0.7;
  color: #9aa2aa;
  font-weight: 700;
}

.search-modal-container2 {
  flex-basis: 90%;
  margin-left: 10px;
  margin-right: 18px;
}

.slider-search {
  margin-right: 18px !important;
  margin-left: 10px !important;
}

.search-modal-container2>ul {
  margin-right: 18px !important;
  margin-left: 10px !important;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 10px;
}

.search-modal-container2>ul>li {
  font-size: 14px;
  margin: 0 10px 8px 0;
  padding-left: 10px;
}

.search-modal-container2>ul>li>label {
  display: block;
  width: 100%;
  min-height: 17px;
  color: #282c3f;
}

.search-modal-container2>ul>li>label>input {
  margin: 0 16px 0 0;
}

.modal-label {
  margin-left: 20px;
  font-weight: 700;
  color: #585858;
}

.modal-reset {
  margin: auto 0;
}

@media only screen and (max-width: 575px) {
  .main-search-container {
    padding: 0;
  }

  .search-right-container {
    width: 100%;
  }

  body {
    background-color: #f6f6f6;
  }

  .search-container2 {
    margin: 0;
  }
}